import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { getVetPets, createVetVisit } from "../connectors/Vet"; // Adjust path as needed
import { FiImage } from "react-icons/fi";
import { UserContext } from "../context/UserContext";
import loadingCat from "../assets/loading_cat.gif";

const NewVisit = ({ onClose, authToken }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pets, setPets] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const limit = 5; // Items per page
  const [selectedPet, setSelectedPet] = useState(null);
  const [visitDate, setVisitDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const { selectedUser } = useContext(UserContext);

  useEffect(() => {
    const fetchPets = async () => {
      if (!authToken) return;
      setLoading(true);
      try {
        const offset = page;
        const results = await getVetPets(offset, limit, searchTerm, authToken);
        setPets(results.pets);
      } catch (error) {
        console.error("Error fetching pets:", error);
      }
      setLoading(false);
    };

    const debounceTimer = setTimeout(() => {
      fetchPets();
    }, 300); // Debounce search input

    return () => clearTimeout(debounceTimer);
  }, [searchTerm, page, authToken]);

  const handlePetSelect = (pet) => {
    setSelectedPet(pet);
  };

  const handleBack = () => {
    setSelectedPet(null);
  };

  const handleCreateVisit = async () => {
    if (!selectedPet || !visitDate) return;

    setIsSubmitting(true);
    setError(null);
    try {
      await createVetVisit(
        selectedPet.ID,
        visitDate,
        selectedUser.ID,
        authToken
      );
      onClose();
    } catch (error) {
      console.error("Error creating visit:", error);
      setError("Failed to create visit. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Overlay>
      <Content>
        <Header>
          <h2>New Vet Visit</h2>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>
        <Body>
          {selectedPet ? (
            <>
              <BackButton onClick={handleBack}>← Back to search</BackButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <VisitCard
                key={selectedPet.ID}
                onClick={() => handlePetSelect(selectedPet)}
              >
                <ImageSection>
                  <PetImage>
                    {selectedPet.petImage ===
                    process.env.REACT_APP_BLOB_STORAGE_URL ? (
                      <FiImage />
                    ) : (
                      <img
                        src={selectedPet.petImage}
                        alt={selectedPet.petName}
                      />
                    )}
                  </PetImage>
                </ImageSection>
                <PetInfo>
                  <PetNameContainer>
                    <PetName>{selectedPet.Name}</PetName>
                    <PetAge>{selectedPet.age} years</PetAge>
                  </PetNameContainer>
                  <PetType>
                    {selectedPet.petTypeImage && (
                      <PetTypeIcon
                        src={selectedPet.petTypeImage}
                        alt={selectedPet.petType}
                      />
                    )}
                    {selectedPet.petType}
                  </PetType>
                  <PetBreed>{selectedPet.petBreed}</PetBreed>
                  <OwnerInfo>{selectedPet.OwnerNameForVet}</OwnerInfo>
                  <OwnerInfo>{selectedPet.OwnerPhoneForVet}</OwnerInfo>
                  <OwnerInfo>{selectedPet.OwnerEmailForVet}</OwnerInfo>
                </PetInfo>
              </VisitCard>

              <VisitDetailsContainer>
                <VisitLabel>Visit Date:</VisitLabel>
                <DateInput
                  type="date"
                  value={visitDate}
                  onChange={(e) => setVisitDate(e.target.value)}
                />
              </VisitDetailsContainer>
              {isSubmitting ? (
                <LoadingContainer>
                  <img src={loadingCat} alt="Loading..." />
                </LoadingContainer>
              ) : (
                <CreateButton
                  onClick={handleCreateVisit}
                  disabled={isSubmitting}
                >
                  Create Visit
                </CreateButton>
              )}
            </>
          ) : (
            <>
              <SearchContainer>
                <SearchInput
                  type="text"
                  placeholder="Search by owner name or phone number or email"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </SearchContainer>

              <ResultsList>
                {loading ? (
                  <LoadingContainer>
                    <img src={loadingCat} alt="Loading..." />
                  </LoadingContainer>
                ) : (
                  pets.map((pet) => (
                    <VisitCard
                      key={pet.ID}
                      onClick={() => handlePetSelect(pet)}
                    >
                      <ImageSection>
                        <PetImage>
                          {pet.petImage ===
                          process.env.REACT_APP_BLOB_STORAGE_URL ? (
                            <FiImage />
                          ) : (
                            <img src={pet.petImage} alt={pet.petName} />
                          )}
                        </PetImage>
                      </ImageSection>
                      <PetInfo>
                        <PetNameContainer>
                          <PetName>{pet.Name}</PetName>
                          <PetAge>{pet.age} years</PetAge>
                        </PetNameContainer>
                        <PetType>
                          {pet.petTypeImage && (
                            <PetTypeIcon
                              src={pet.petTypeImage}
                              alt={pet.petType}
                            />
                          )}
                          {pet.petType}
                        </PetType>
                        <PetBreed>{pet.petBreed}</PetBreed>
                        <OwnerInfo>{pet.OwnerNameForVet}</OwnerInfo>
                        <OwnerInfo>{pet.OwnerPhoneForVet}</OwnerInfo>
                        <OwnerInfo>{pet.OwnerEmailForVet}</OwnerInfo>
                      </PetInfo>
                    </VisitCard>
                  ))
                )}
              </ResultsList>

              <Pagination>
                <PaginationButton
                  onClick={() => setPage((p) => Math.max(1, p - 1))}
                  disabled={page === 1}
                >
                  Previous
                </PaginationButton>
                <PageNumber>Page {page}</PageNumber>
                <PaginationButton
                  onClick={() => setPage((p) => p + 1)}
                  disabled={pets.length < limit}
                >
                  Next
                </PaginationButton>
              </Pagination>
            </>
          )}
        </Body>
      </Content>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 85%;
  height: 93%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    flex-grow: 1;
    text-align: center;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  color: #5951fc;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
`;

const SearchInput = styled.input`
  width: 80%;
  max-width: 500px;
  padding: 12px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: #999;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    &::placeholder {
      font-size: 12px;
    }
  }
`;

const ResultsList = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  flex: 1;
  padding-right: 10px;

  /* Add custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ImageSection = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PetImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    color: #999;
    width: 40%;
    height: 40%;
  }
`;

const PetName = styled.span`
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const PetNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PetType = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #666;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const PetBreed = styled.span`
  color: #007bff;
  font-size: 13px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const PetAge = styled.span`
  font-size: 12px;
  color: #666;
`;

const PetInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    width: 100px;
    height: auto;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

const PaginationButton = styled.button`
  padding: 8px 16px;
  background-color: #5951fc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 12px;
  }

  &:hover {
    background-color: #4842e3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const PageNumber = styled.span`
  padding: 0 10px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
`;

const VisitDetailsContainer = styled.div`
  margin-top: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
`;

const VisitLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
`;

const DateInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 200px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

const CreateButton = styled.button`
  display: block;
  margin: 20px auto 0;
  padding: 12px 24px;
  background-color: #5951fc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: fit-content;

  &:hover {
    background-color: #4842e3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  background-color: #f8d7;
`;

const PetTypeIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  filter: invert(37%) sepia(14%) saturate(6889%) hue-rotate(232deg)
    brightness(99%) contrast(98%);
  opacity: 0.5;
`;

const VisitCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin: 8px 0;
  display: flex;
  gap: 15px;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 10px;
  }
`;

const OwnerInfo = styled.span`
  font-size: 13px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export default NewVisit;
