import React, { useEffect, useState, useContext } from 'react';
import TextField from '../../components/TextField';
import AnimalHouseImage from '../../assets/AnimalHouse.png';
import styled from 'styled-components';
import Button from '../../components/Button';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import loadingCat from '../../assets/loading_cat.gif';

const AnimalHouseLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Check localStorage for saved username and password
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    if (savedUsername) setUsername(savedUsername);
    if (savedPassword) setPassword(savedPassword);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    localStorage.setItem('username', username);
    localStorage.setItem('password', password);
    
    try {
      setError('');
      await login(username, password);
      navigate('/Dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Logo src={AnimalHouseImage} alt="Animal House" />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        
        {isLoading ? (
          <LoadingContainer>
            <LoadingGif src={loadingCat} alt="Loading..." />
          </LoadingContainer>
        ) : (
          <InputContainer>
            <div>
              <TextField 
                type="username"
                placeholder="Username"
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                required 
              />
            </div>
            <div>
              <TextField 
                type="password" 
                placeholder="Password"
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
            </div>
            <div>
              <Button 
                type="submit" 
                color="#5951fc" 
                style={{ marginTop: '20px', padding: '15px 30px', fontSize: '20px' }}
              >
                Login
              </Button>
            </div>
          </InputContainer>
        )}
      </Form>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #6a11cb, #aeabff, #5951fc);
  background-size: 300% 300%;
  animation: gradientAnimation 8s ease infinite;

  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const Logo = styled.img`
  width: 200px;
  height: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  min-height: 450px;
  max-width: 600px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (min-width: 468px) {
    width: 90%;
    padding: 40px;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  
  > div {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

const LoadingGif = styled.img`
  width: 150px;
  height: auto;
`;

export default AnimalHouseLogin;

