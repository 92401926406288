import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import styled from "styled-components";
import { getVetVisits, deleteVetVisit } from "../connectors/Vet";
import { UserContext } from "../context/UserContext";
import { FiTrash2, FiImage } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import NewVisit from "../components/NewVisit";
import EditVisit from "../components/EditVisit";
import loadingCat from "../assets/loading_cat.gif";

const Visits = () => {
  const [visits, setVisits] = useState({ visits: [] });
  const [filter, setFilter] = useState("active");
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const limit = 4;
  const { user } = useContext(UserContext);
  const token = user?.token;
  const [isPulling, setIsPulling] = useState(false);
  const [pullStartY, setPullStartY] = useState(0);
  const scrollContainerRef = useRef(null);
  const [showNewVisit, setShowNewVisit] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchVisits = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    try {
      let isFinished;
      switch (filter) {
        case "active":
          isFinished = 0;
          break;
        case "completed":
          isFinished = 1;
          break;
        default: // 'all'
          isFinished = 2;
      }

      const offset = currentPage;

      const data = await getVetVisits(isFinished, offset, limit, token);
      setVisits(data);
      setErrorMessage("");
    } catch (error) {
      console.error("Error fetching vet visits:", error);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, filter, token, limit]);

  useEffect(() => {
    fetchVisits();
  }, [fetchVisits]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    if (visits.visits.length === limit) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleDeleteVisit = (visitId, petId) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomAlertContainer>
          <AlertTitle>Confirm Deletion</AlertTitle>
          <AlertMessage>
            Are you sure you want to delete this visit?
          </AlertMessage>
          <AlertButtonContainer>
            <AlertButton
              onClick={async () => {
                try {
                  await deleteVetVisit(visitId, petId, token);
                  // Refresh the visits list after successful deletion
                  const isFinished = filter === "active" ? 0 : 1;
                  const data = await getVetVisits(
                    isFinished,
                    currentPage,
                    limit,
                    token
                  );
                  setVisits(data);
                  setErrorMessage("");
                  onClose();
                } catch (error) {
                  console.error("Error deleting visit:", error);
                  setErrorMessage("Failed to delete visit: " + error.message);
                  onClose();
                }
              }}
            >
              Yes
            </AlertButton>
            <AlertButton onClick={onClose}>No</AlertButton>
          </AlertButtonContainer>
        </CustomAlertContainer>
      ),
    });
  };

  const handleTouchStart = (e) => {
    // Only enable pull-to-refresh when at the top of the container
    if (scrollContainerRef.current.scrollTop === 0) {
      setPullStartY(e.touches[0].clientY);
    }
  };

  const handleTouchMove = (e) => {
    if (pullStartY === 0) return;

    const pullDistance = e.touches[0].clientY - pullStartY;
    if (pullDistance > 50) {
      setIsPulling(true);
    }
  };

  const handleTouchEnd = async () => {
    if (isPulling) {
      // Reset states
      setIsPulling(false);
      setPullStartY(0);

      // Reset to first page and show loading
      setCurrentPage(1);
      setIsLoading(true);

      // Refresh data
      try {
        let isFinished;
        switch (filter) {
          case "active":
            isFinished = 0;
            break;
          case "completed":
            isFinished = 1;
            break;
          default: // 'all'
            isFinished = 2;
        }
        const data = await getVetVisits(isFinished, 1, limit, token);
        setVisits(data);
        setErrorMessage("");
      } catch (error) {
        console.error("Error refreshing vet visits:", error);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleNewVisit = () => {
    setShowNewVisit(true);
  };

  const handleVisitClick = (visit) => {
    setSelectedVisit({
      ...visit,
      visitId: visit.ID,
    });
  };

  const handleEditVisitClose = async () => {
    setSelectedVisit(null);
    await fetchVisits();
  };

  const handleNewVisitClose = () => {
    setShowNewVisit(false);
    fetchVisits(); // Refresh the visits list when NewVisit is closed
  };

  return (
    <Container>
      <h1>Vet Visits</h1>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <ButtonContainer>
        <FilterButtonGroup>
          <FilterButton
            isActive={filter === "all"}
            onClick={() => handleFilterChange("all")}
          >
            All
          </FilterButton>
          <FilterButton
            isActive={filter === "active"}
            onClick={() => handleFilterChange("active")}
          >
            <Dot color="green" />
            Active
          </FilterButton>
          <FilterButton
            isActive={filter === "completed"}
            onClick={() => handleFilterChange("completed")}
          >
            <Dot color="#ed4752" />
            Completed
          </FilterButton>
        </FilterButtonGroup>
        <NewVisitButton onClick={handleNewVisit}>+ New Visit</NewVisitButton>
      </ButtonContainer>
      <TotalCount>Total Visits: {visits.count}</TotalCount>
      {showNewVisit && (
        <NewVisit onClose={handleNewVisitClose} authToken={token} />
      )}
      {selectedVisit && (
        <EditVisit
          visit={selectedVisit}
          visitId={selectedVisit.ID}
          onClose={handleEditVisitClose}
          authToken={token}
        />
      )}
      <ScrollableContainer
        ref={scrollContainerRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {isPulling && (
          <RefreshIndicator>Release to refresh...</RefreshIndicator>
        )}
        {isLoading ? (
          <LoadingContainer>
            <LoadingImage src={loadingCat} alt="Loading..." />
          </LoadingContainer>
        ) : (
          <StyledList>
            {visits?.visits?.map((visit) => (
              <VisitCard
                key={visit.ID}
                onClick={() => handleVisitClick(visit)}
                style={{ cursor: "pointer" }}
              >
                <ImageSection>
                  <PetImage>
                    {visit.petImage ===
                    process.env.REACT_APP_BLOB_STORAGE_URL ? (
                      <FiImage />
                    ) : (
                      <img src={visit.petImage} alt={visit.petName} />
                    )}
                  </PetImage>
                </ImageSection>
                <VisitInfo>
                  <PetNameContainer>
                    <PetName>{visit.petName}</PetName>
                    <PetAge>{visit.age} years</PetAge>
                  </PetNameContainer>
                  <PetType>
                    {visit.petTypeImage && (
                      <PetTypeIcon
                        src={visit.petTypeImage}
                        alt={visit.petType}
                      />
                    )}
                    {visit.petType}
                  </PetType>
                  <PetBreed>{visit.petBreed}</PetBreed>
                  <OwnerInfo>{visit.ownerNameForVet}</OwnerInfo>
                  <OwnerInfo>{visit.ownerPhoneForVet}</OwnerInfo>
                  <OwnerInfo>{visit.ownerEmailForVet}</OwnerInfo>
                  <VisitDate>
                    <strong>Visit Date:</strong>{" "}
                    {new Date(visit.VisitDate).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </VisitDate>
                </VisitInfo>
                <ActionButtons>
                  <ActionButton
                    variant="delete"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event from bubbling up to the card
                      handleDeleteVisit(visit.ID, visit.PetProfileID);
                    }}
                  >
                    <FiTrash2 size={14} />
                    Delete
                  </ActionButton>
                </ActionButtons>
              </VisitCard>
            ))}
          </StyledList>
        )}
      </ScrollableContainer>
      <PaginationContainer>
        <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </PaginationButton>
        <PageNumber>Page {currentPage}</PageNumber>
        <PaginationButton
          onClick={handleNextPage}
          disabled={visits.visits.length < limit}
        >
          Next
        </PaginationButton>
      </PaginationContainer>
    </Container>
  );
};

const ButtonContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterButton = styled.button`
  padding: 10px 15px;
  margin-right: 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s, color 0.3s;

  ${({ isActive }) =>
    isActive &&
    `
        background-color: orange;
        color: white;
    `}

  @media (max-width: 768px) {
    padding: 6px 10px;
    font-size: 12px;
    margin-right: 5px;
  }
`;

const Container = styled.div`
  margin-bottom: 20px;
  height: 90%;
  display: flex;
  flex-direction: column;
  h1 {
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 80px;
  }
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.color || "green"};
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 8px;
  flex-shrink: 0; // Prevent pagination from shrinking

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const PaginationButton = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #5951fc;
  color: white;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 6px;

  &:before {
    content: ${(props) =>
      props.children === "Previous"
        ? '"←"'
        : props.children === "Next"
        ? '"→"'
        : '""'};
    font-size: 16px;
    ${(props) => props.children === "Next" && "order: 1;"}
  }

  &:disabled {
    background-color: #f5f5f5;
    color: #bdbdbd;
    border-color: #e0e0e0;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #5951fc;
    color: white;
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 11px;

    &:before {
      font-size: 12px;
    }
  }
`;

const PageNumber = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #555;
  background-color: #f8f9fa;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 11px;
  }
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  min-height: 0; // This is important for flex containers
  overscroll-behavior-y: contain;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const VisitCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin: 8px 0;
  display: flex;
  gap: 15px;
  position: relative;

  @media (max-width: 768px) {
    padding: 8px;
    gap: 8px;
  }
`;

const ImageSection = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PetImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    color: #999;
    width: 40%;
    height: 40%;
  }
`;

const VisitInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: 768px) {
    gap: 2px;
  }
`;

const PetName = styled.span`
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const PetType = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #666;
`;

const PetBreed = styled.span`
  color: #007bff;
  font-size: 13px;
`;

const OwnerInfo = styled.span`
  font-size: 13px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const VisitDate = styled.span`
  font-size: 13px;
  color: #666;
`;

const ActionButtons = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;

  @media (max-width: 768px) {
    top: 8px;
    right: 8px;
  }
`;

const ActionButton = styled.button`
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  transition: all 0.2s ease;

  ${({ variant }) =>
    variant === "edit" &&
    `
        border: 1px solid #007bff;
        color: #007bff;
        &:hover {
            background-color: rgba(0, 123, 255, 0.1);
        }
    `}

  ${({ variant }) =>
    variant === "delete" &&
    `
        border: 1px solid #dc3545;
        color: #dc3545;
        &:hover {
            background-color: rgba(220, 53, 69, 0.1);
        }
    `}

    @media (max-width: 768px) {
    padding: 2px 6px;
    font-size: 10px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const RefreshIndicator = styled.div`
  text-align: center;
  padding: 10px;
  color: #666;
  background-color: #f5f5f5;
`;

const CustomAlertContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const AlertTitle = styled.h1`
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
`;

const AlertMessage = styled.p`
  margin-bottom: 20px;
  color: #666;
`;

const AlertButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const AlertButton = styled.button`
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #5951fc;
  color: white;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

const FilterButtonGroup = styled.div`
  display: flex;
`;

const NewVisitButton = styled(FilterButton)`
  background-color: #5951fc;
  color: white;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
    font-size: 12px;
  }
`;

const TotalCount = styled.div`
  font-size: 14px;
  color: #666;
`;

const PetTypeIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  filter: invert(37%) sepia(14%) saturate(6889%) hue-rotate(232deg)
    brightness(99%) contrast(98%);
  opacity: 0.5;
`;

const PetNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PetAge = styled.span`
  font-size: 14px;
  color: #666;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const LoadingImage = styled.img`
  width: 150px;
  height: auto;
  object-fit: contain;
`;

export default Visits;
