import React, { useEffect, useState, useContext, useRef } from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import { UserContext } from "../../context/UserContext";
import logowhite from "../../assets/logowhite.png"; // Imported logo image
import { FaClipboardList, FaPaw, FaBell, FaUser } from "react-icons/fa"; // Imported icons
import Visits from "../../components/Visits";
import Notifications from "../../components/Notifications";
import VetPets from "../../components/VetPets";

const Dashboard = () => {
  const { user, selectUser, selectedUser } = useContext(UserContext);
  const token = user?.token;
  const users = user?.users;
  const [selectedButton, setSelectedButton] = useState("Visits"); // New state for selected button
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Add this state
  const [currentUser, setCurrentUser] = useState(null); // Changed from selectedUser
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Add default user selection
    if (users && users.length > 0 && !currentUser) {
      // Changed from selectedUser
      selectUser(selectedUser);
      setCurrentUser(selectedUser); // Changed from setSelectedUser
    }
  }, [token, users, currentUser, selectUser, selectedUser]); // Changed dependency from selectedUser

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container>
      <Logo src={logowhite} alt="Logo" />
      <SubContainer>
        <ButtonContainer>
          <LeftButtonGroup>
            <ButtonStyled
              onClick={() => setSelectedButton("Visits")}
              style={{
                backgroundColor:
                  selectedButton === "Visits" ? "#5951fc" : "white",
                color: selectedButton === "Visits" ? "white" : "grey",
              }}
            >
              <FaClipboardList style={{ marginRight: "5px" }} /> Visits
            </ButtonStyled>
            <ButtonStyled
              onClick={() => setSelectedButton("Pets")}
              style={{
                backgroundColor:
                  selectedButton === "Pets" ? "#5951fc" : "white",
                color: selectedButton === "Pets" ? "white" : "grey",
              }}
            >
              <FaPaw style={{ marginRight: "5px" }} /> Pets
            </ButtonStyled>
            <ButtonStyled
              onClick={() => setSelectedButton("Notifications")}
              style={{
                backgroundColor:
                  selectedButton === "Notifications" ? "#5951fc" : "white",
                color: selectedButton === "Notifications" ? "white" : "grey",
              }}
            >
              <FaBell style={{ marginRight: "5px" }} /> Notifications
            </ButtonStyled>
          </LeftButtonGroup>
          <DropdownContainer ref={dropdownRef}>
            <ButtonStyled
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              style={{
                backgroundColor: isDropdownOpen ? "#5951fc" : "white",
                color: isDropdownOpen ? "white" : "grey",
              }}
              isDropdown
            >
              <FaUser style={{ marginRight: "5px" }} />{" "}
              {currentUser?.Name || "Select User"}
            </ButtonStyled>
            {isDropdownOpen && (
              <DropdownContent>
                {users?.map((user) => (
                  <DropdownItem
                    key={user.id}
                    onClick={() => {
                      selectUser(user);
                      setCurrentUser(user);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {user.Name}
                  </DropdownItem>
                ))}
              </DropdownContent>
            )}
          </DropdownContainer>
        </ButtonContainer>
        {/* Content for the sub-container goes here */}
        {selectedButton === "Visits" && <Visits />}
        {selectedButton === "Pets" && <VetPets />}
        {selectedButton === "Notifications" && <Notifications />}
      </SubContainer>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100dvh;
  width: 100dvw;
  padding: 5px 15px;
  box-sizing: border-box;
  background: linear-gradient(135deg, #6a11cb, #aeabff, #5951fc);
  background-size: 300% 300%;
  animation: gradientAnimation 8s ease infinite;

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const SubContainer = styled.div`
  background-color: white;
  width: calc(100% - 32px); // Explicit calculation for width
  height: calc(95% - 50px); // Adjust height to account for padding and logo
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`;

const Logo = styled.img`
  width: 202px;
  height: 40px;
  margin: 8px 0 0 0;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
`;

const LeftButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonStyled = styled(Button)`
  border: 1px solid grey;
  color: grey;
  border-radius: ${(props) => (props.isDropdown ? "5px" : "20px")};
  background-color: white;
  white-space: nowrap;
  display: flex;
  align-items: center;

  // Responsive sizing
  @media (min-width: 769px) {
    padding: 10px 20px;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 12px;

    // Make icons smaller on mobile
    svg {
      width: 12px;
      height: 12px;
      margin-right: 4px !important;
    }
  }

  &:hover {
    background-color: #5951fc;
    color: white;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1;
  margin-top: 5px;
`;

const DropdownItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export default Dashboard;
