// utils/request.js

// Get the hostname from environment variables
const API_HOST = process.env.REACT_APP_HOSTNAME;

// Function to make a GET request
export const getRequest = async (url, token) => {
  try {
    const headers = {};

    // If a token is provided, add it to the Authorization header
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(`${API_HOST}${url}`, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Failed to fetch data:', error);
    throw error; // Re-throw the error for handling in the calling function
  }
};

// Function to make a POST request
export const postRequest = async (url, data, token) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };

    // If a token is provided, add it to the Authorization header
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(`${API_HOST}${url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Failed to post data:', error);
    throw error; // Re-throw the error for handling in the calling function
  }
};

// Function to make a POST request with files using FormData
export const postFormDataRequest = async (url, data, files, token) => {
  try {
    const formData = new FormData();

    // Append JSON data as a string under a single key
    formData.append('json', JSON.stringify(data));

    // Append files to FormData
    (files || []).forEach((fileObj, index) => {
        if (fileObj.file) { // Ensure it's a file
            formData.append('imageFile', fileObj.file); // Adjust 'imageFile' if backend expects a different key
        }
    });

    const headers = {};
    // Add authorization if token is provided
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(`${API_HOST}${url}`, {
      method: 'POST',
      headers: headers,
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Failed to upload data:', error);
    throw error;
  }
};