import { postRequest, getRequest } from '../utils/request';

// acceptPetInvite endpoint
export const acceptPetInvite = async (verificationToken) => {
  try {
    const body = {
        verificationToken: verificationToken,
      };
    const response = await postRequest('/api/Pets/AcceptPetInvite', body);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to reset password:', error);
    throw error;
  }
};

// getPetTypes endpoint
export const getPetTypes = async () => {
    const response = await getRequest('/api/General/GetPetTypes');
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetsForAdoption endpoint
export const getPetsForAdoption = async (petTypeId, offset, limit) => {
    const response = await getRequest(`/api/Pets/GetPetsForAdoption?petTypeId=${petTypeId}&offset=${offset}&limit=${limit}&searchTerm=`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getLostPets endpoint
export const getLostPets = async (petTypeId, offset, limit) => {
    const response = await getRequest(`/api/Pets/GetLostPets?petTypeId=${petTypeId}&offset=${offset}&limit=${limit}&searchTerm=`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetDetailsByPublicId endpoint
export const getPetDetailsByPublicId = async (petProfileId, publicId, latitude, longitude) => {
    const response = await getRequest(`/api/Pets/GetPetDetailsByPublicId?&petProfileId=${petProfileId || ''}&publicId=${publicId || ''}&profileNeeded=&latitude=${latitude || ''}&longitude=${longitude || ''}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetWellnessCategories endpoint
export const getPetWellnessCategories = async () => {
    const response = await getRequest('/api/PetWellness/GetPetWellnessCategories');
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetWellnessCategoryTypes endpoint
export const getPetWellnessCategoryTypes = async (petId, publicId, categoryid) => {
    const response = await getRequest(`/api/PetWellness/GetPetWellnessCategoryTypes?petProfile=${petId}&publicId=${publicId}&categoryId=${categoryid}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetGroomerById endpoint
export const getPetGroomerById = async (groomerid) => {
    const response = await getRequest(`/api/PetGroomers/GetPetGroomerById?id=${groomerid}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetGroomers endpoint
export const getPetGroomers = async (offset, limit, selectedCountry) => {
    const response = await getRequest(`/api/PetGroomers/GetPetGroomers?offset=${offset}&limit=${limit}&SearchTerm=&CurrencyId=&PricingTypeId=&PriceFrom=&PriceTo=&CountryId=${selectedCountry}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetSitterById endpoint
export const getPetSitterById = async (sitterid) => {
    const response = await getRequest(`/api/PetSitters/GetPetSitterById?id=${sitterid}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetSitters endpoint
export const getPetSitters = async (offset, limit, selectedCountry) => {
    const response = await getRequest(`/api/PetSitters/GetPetSitters?offset=${offset}&limit=${limit}&SearchTerm=&CurrencyId=&PricingTypeId=&PriceFrom=&PriceTo=&CountryId=${selectedCountry}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetTrainerById endpoint
export const getPetTrainerById = async (trainerid) => {
    const response = await getRequest(`/api/PetTrainers/GetPetTrainerById?id=${trainerid}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetTrainers endpoint
export const getPetTrainers = async (offset, limit, selectedCountry) => {
    const response = await getRequest(`/api/PetTrainers/GetPetTrainers?offset=${offset}&limit=${limit}&SearchTerm=&CurrencyId=&PricingTypeId=&PriceFrom=&PriceTo=&CountryId=${selectedCountry}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getVetById endpoint
export const getVetById = async (vetid) => {
    const response = await getRequest(`/api/PetVeterinarians/GetVetById?id=${vetid}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getVets endpoint
export const getVets = async (offset, limit, selectedCountry) => {
    const response = await getRequest(`/api/PetVeterinarians/GetVets?offset=${offset}&limit=${limit}&SearchTerm=&CurrencyId=&PricingTypeId=&PriceFrom=&PriceTo=&CountryId=${selectedCountry}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetWalkerById endpoint
export const getPetWalkerById = async (walkerid) => {
    const response = await getRequest(`/api/PetWalkers/GetPetWalkerById?id=${walkerid}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getPetWalkers endpoint
export const getPetWalkers = async (offset, limit, selectedCountry) => {
    const response = await getRequest(`/api/PetWalkers/GetPetWalkers?offset=${offset}&limit=${limit}&SearchTerm=&CurrencyId=&PricingTypeId=&PriceFrom=&PriceTo=&CountryId=${selectedCountry}`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};