import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AdoptPet from './pages/Pet/AdoptPet';
import PetSitters from './pages/PetCareServices/PetSitters';
import PetTrainers from './pages/PetCareServices/PetTrainers';
import PetWalkers from './pages/PetCareServices/PetWalkers';
import PetGroomers from './pages/PetCareServices/PetGroomers';
import PetVets from './pages/PetCareServices/PetVets';
import PetProfile from './pages/Pet/PetProfile';
import PetSitterDetail from './pages/PetCareServices/PetSitterDetail';
import PetTrainerDetail from './pages/PetCareServices/PetTrainerDetail';
import PetGroomerDetail from './pages/PetCareServices/PetGroomerDetail';
import PetWalkerDetail from './pages/PetCareServices/PetWalkerDetail';
import PetVetDetail from './pages/PetCareServices/PetVetDetail';
import Support from './pages/Legal/Support';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsAndConditions from './pages/Legal/TermsAndConditions';
import ResetPassword from './pages/Authentication/ResetPassword';
import VerifyEmail from './pages/Authentication/VerifyEmail';
import AcceptPetInvite from './pages/Pet/AcceptPetInvite';
import LostPets from './pages/Pet/LostPets';
import AnimalHouseLogin from './pages/AnimalHouse/AnimalHouseLogin';
import Dashboard from './pages/AnimalHouse/Dashboard';
import { UserProvider } from './context/UserContext';

function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/adopt" element={<AdoptPet />} />
          <Route path="/lost" element={<LostPets />} />
          <Route path="/sitters" element={<PetSitters />} />
          <Route path="/trainers" element={<PetTrainers />} />
          <Route path="/walkers" element={<PetWalkers />} />
          <Route path="/groomers" element={<PetGroomers />} />
          <Route path="/vets" element={<PetVets />} />
          <Route path="/Pets/Details" element={<PetProfile />} />
          <Route path="/pet-sitter/:id" element={<PetSitterDetail />} />
          <Route path="/pet-trainer/:id" element={<PetTrainerDetail />} />
          <Route path="/pet-walker/:id" element={<PetWalkerDetail />} />
          <Route path="/pet-groomer/:id" element={<PetGroomerDetail />} />
          <Route path="/pet-vet/:id" element={<PetVetDetail />} />
          <Route path="/support" element={<Support />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/VerifyEmail" element={<VerifyEmail />} />
          <Route path="/AcceptPetInvite" element={<AcceptPetInvite />} />
          <Route path="/AnimalHouse" element={<AnimalHouseLogin />} />
          <Route path="/Dashboard" element={<Dashboard />} />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
