import React, { useState, useEffect } from "react";
import styled from "styled-components";
import loadingCat from "../assets/loading_cat.gif"; // Add this import at the top
import {
  getVetPetDetails,
  getPetWellnessCategories,
  getPetWellnessCategoryTypes,
  getVetPetVisits,
} from "../connectors/Vet";
import {
  FiImage,
  FiInfo,
  FiActivity,
  FiHeart,
  FiCalendar,
  FiScissors,
  FiDroplet,
  FiHash,
  FiBox,
} from "react-icons/fi";
import EditVisit from "./EditVisit"; // Add this import

const VetPetProfile = ({ pet, onClose, authToken }) => {
  // Add state for visit details
  const [isLoadingDetails, setIsLoadingDetails] = useState(true); // Rename existing loading state
  const [error, setError] = useState(null);
  const [isSubmitting] = useState(false); // Add this new state
  const [petDetails, setPetDetails] = useState(null); // Add this state
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(1); // Default to "About" tab
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [isLoadingCategoryTypes, setIsLoadingCategoryTypes] = useState(false);
  const [activeSubType, setActiveSubType] = useState(null);
  const [visits, setVisits] = useState([]);
  const [totalVisits, setTotalVisits] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const VISITS_PER_PAGE = 4;
  const [isLoadingVisits, setIsLoadingVisits] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState(null);

  // Add useEffect to fetch pet details when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [petDetailsResponse, categoriesResponse] = await Promise.all([
          getVetPetDetails(pet.ID, authToken),
          getPetWellnessCategories(authToken),
        ]);

        setPetDetails(petDetailsResponse);
        // Add "About" category and sort by ID
        const allCategories = [
          { id: 1, title: "About" },
          ...categoriesResponse,
        ].sort((a, b) => a.id - b.id);
        setCategories(allCategories);
        setIsLoadingDetails(false);
      } catch (err) {
        setError("Failed to load data. Please try again later.");
        setIsLoadingDetails(false);
      }
    };

    fetchData();
  }, [pet.ID, authToken]);

  // Add useEffect to fetch category types when tab changes
  useEffect(() => {
    const fetchCategoryTypes = async () => {
      if (activeTab === 1) return; // Skip for "About" tab

      setIsLoadingCategoryTypes(true);
      try {
        const response = await getPetWellnessCategoryTypes(
          pet.ID,
          activeTab,
          authToken
        );
        setCategoryTypes(response);
        // Set the first subtype as active by default
        if (response.length > 0) {
          setActiveSubType(response[0].id);
        }
      } catch (err) {
        setError("Failed to load category types. Please try again later.");
      } finally {
        setIsLoadingCategoryTypes(false);
      }
    };

    fetchCategoryTypes();
  }, [activeTab, pet.ID, authToken]);

  // Add this useEffect after the existing ones
  useEffect(() => {
    const fetchVisits = async () => {
      setIsLoadingVisits(true);
      try {
        const response = await getVetPetVisits(
          pet.ID,
          currentPage,
          VISITS_PER_PAGE,
          authToken
        );
        setVisits(response.visits);
        setTotalVisits(response.count);
      } catch (err) {
        console.error("Failed to fetch visits:", err);
      } finally {
        setIsLoadingVisits(false);
      }
    };

    if (activeTab === 1) {
      fetchVisits();
    }
  }, [pet.ID, authToken, currentPage, activeTab]);

  // Helper function to get icon for category
  const getCategoryIcon = (id) => {
    switch (id) {
      case 1:
        return <FiInfo />;
      case 2:
        return <FiActivity />;
      case 4:
        return <FiHeart />;
      case 5:
        return <FiDroplet />;
      default:
        return null;
    }
  };

  // Add loading and error states to the return
  return (
    <Overlay>
      <Content>
        <Header>
          <h2>Pet Profile</h2>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>

        {isLoadingDetails || isSubmitting ? (
          <ContentArea>
            <LoadingImage src={loadingCat} alt="Loading..." />
          </ContentArea>
        ) : error ? (
          <ContentArea>
            <ErrorMessage>{error}</ErrorMessage>
          </ContentArea>
        ) : (
          <>
            <PetProfile>
              {petDetails.petImage ===
              process.env.REACT_APP_BLOB_STORAGE_URL ? (
                <FiImage size={100} color="#999" />
              ) : (
                <PetImage src={petDetails.petImage} alt={petDetails.petName} />
              )}
              <PetInfo>
                <h3>
                  {petDetails.petName}
                  <PetAge>{petDetails.age} years</PetAge>
                </h3>
                <PetType>
                  {petDetails.petTypeImage && (
                    <PetTypeIcon
                      src={petDetails.petTypeImage}
                      alt={petDetails.petType}
                    />
                  )}
                  {petDetails.petType}
                </PetType>
                <p>Breed: {petDetails.petBreed}</p>
                <p>Gender: {petDetails.petGender}</p>
                <p>Owner: {petDetails.ownerNameForVet}</p>
                <p>Contact: {petDetails.ownerPhoneForVet}</p>
                <p>Email: {petDetails.ownerEmailForVet}</p>
              </PetInfo>
            </PetProfile>

            <TabContainer>
              {categories.map((category, index) => (
                <React.Fragment key={category.id}>
                  <Tab
                    isActive={activeTab === category.id}
                    onClick={() => setActiveTab(category.id)}
                  >
                    {getCategoryIcon(category.id)}
                    {category.title}
                  </Tab>
                  {index < categories.length - 1 && <Divider>|</Divider>}
                </React.Fragment>
              ))}
            </TabContainer>

            {activeTab === 1 && (
              <ScrollableContent>
                <AboutSection>
                  <InfoRowContainer>
                    <InfoCard>
                      <InfoIcon>
                        <FiCalendar />
                      </InfoIcon>
                      <InfoContent>
                        <InfoLabel>Date of Birth</InfoLabel>
                        <InfoValue>
                          {new Date(petDetails.dob).toLocaleDateString()}
                        </InfoValue>
                      </InfoContent>
                    </InfoCard>

                    <InfoCard>
                      <InfoIcon>
                        <FiScissors />
                      </InfoIcon>
                      <InfoContent>
                        <InfoLabel>Sterilized</InfoLabel>
                        <InfoValue>
                          {petDetails.isSterilized === null
                            ? "Not specified"
                            : petDetails.isSterilized
                            ? "Yes"
                            : "No"}
                        </InfoValue>
                      </InfoContent>
                    </InfoCard>
                  </InfoRowContainer>

                  <InfoRowContainer>
                    <InfoCard>
                      <InfoIcon>
                        <FiDroplet />
                      </InfoIcon>
                      <InfoContent>
                        <InfoLabel>Blood Type</InfoLabel>
                        <InfoValue>
                          {petDetails.bloodType || "Unknown"}
                        </InfoValue>
                      </InfoContent>
                    </InfoCard>

                    <InfoCard>
                      <InfoIcon>
                        <FiHash />
                      </InfoIcon>
                      <InfoContent>
                        <InfoLabel>Microchip ID</InfoLabel>
                        <InfoValue>
                          {petDetails.microChipId || "Not specified"}
                        </InfoValue>
                      </InfoContent>
                    </InfoCard>
                  </InfoRowContainer>

                  <InfoRowContainer>
                    <InfoCard>
                      <InfoIcon>
                        <FiBox />
                      </InfoIcon>
                      <InfoContent>
                        <InfoLabel>Weight</InfoLabel>
                        <InfoValue>
                          {petDetails.weight || "Not specified"}
                        </InfoValue>
                      </InfoContent>
                    </InfoCard>
                  </InfoRowContainer>

                  <SectionDivider />
                  <VisitsSection>
                    <VisitsHeader>
                      <SectionTitle>Visit History</SectionTitle>
                      <VisitCount>Total Visits: {totalVisits}</VisitCount>
                    </VisitsHeader>
                    {isLoadingVisits ? (
                      <LoadingContainer>
                        <LoadingImage src={loadingCat} alt="Loading..." />
                      </LoadingContainer>
                    ) : visits.length > 0 ? (
                      <>
                        <VisitsList>
                          {visits.map((visit) => (
                            <VisitCard
                              key={visit.id}
                              onClick={() => setSelectedVisit(visit)}
                            >
                              <VisitIcon>
                                <FiCalendar />
                              </VisitIcon>
                              <VisitInfo>
                                <VisitDate>
                                  {new Date(
                                    visit.visitDate
                                  ).toLocaleDateString()}
                                </VisitDate>
                                <VisitVet>{visit.vetName}</VisitVet>
                              </VisitInfo>
                            </VisitCard>
                          ))}
                        </VisitsList>
                        {totalVisits > VISITS_PER_PAGE && (
                          <Pagination>
                            <PaginationButton
                              disabled={currentPage === 1}
                              onClick={() => setCurrentPage((prev) => prev - 1)}
                            >
                              Previous
                            </PaginationButton>
                            <PageInfo>
                              Page {currentPage} of{" "}
                              {Math.ceil(totalVisits / VISITS_PER_PAGE)}
                            </PageInfo>
                            <PaginationButton
                              disabled={
                                currentPage >=
                                Math.ceil(totalVisits / VISITS_PER_PAGE)
                              }
                              onClick={() => setCurrentPage((prev) => prev + 1)}
                            >
                              Next
                            </PaginationButton>
                          </Pagination>
                        )}
                      </>
                    ) : (
                      <NoVisits>No visits recorded</NoVisits>
                    )}
                  </VisitsSection>
                </AboutSection>
              </ScrollableContent>
            )}

            {activeTab !== 1 && (
              <ScrollableContent>
                {isLoadingCategoryTypes ? (
                  <LoadingContainer>
                    <LoadingImage src={loadingCat} alt="Loading..." />
                  </LoadingContainer>
                ) : (
                  <>
                    <SubTypeTabContainer>
                      {categoryTypes.map((type) => (
                        <SubTypeTab
                          key={type.id}
                          isActive={activeSubType === type.id}
                          onClick={() => setActiveSubType(type.id)}
                        >
                          {type.title}
                        </SubTypeTab>
                      ))}
                    </SubTypeTabContainer>

                    {categoryTypes.map(
                      (type) =>
                        type.id === activeSubType && (
                          <CategoryTypeSection key={type.id}>
                            {type.id === 18 ? ( // Pictures section
                              <div>
                                {Object.entries(
                                  type.records.reduce((groups, record) => {
                                    const date = new Date(
                                      record.actionDate
                                    ).toLocaleDateString();
                                    if (!groups[date]) groups[date] = [];
                                    groups[date].push(record);
                                    return groups;
                                  }, {})
                                )
                                  .sort(
                                    ([dateA], [dateB]) =>
                                      new Date(dateB) - new Date(dateA)
                                  )
                                  .map(([date, records]) => (
                                    <CategoryTypeSection key={date}>
                                      <TypeHeader>
                                        <h4>{date}</h4>
                                      </TypeHeader>
                                      <ImageGrid>
                                        {records.map((record) => (
                                          <ImageContainer key={record.id}>
                                            <StyledImage
                                              src={record.imagePath}
                                              alt={`Medical record from ${date}`}
                                            />
                                            {record.notes && (
                                              <ImageNotes>
                                                {record.notes}
                                              </ImageNotes>
                                            )}
                                          </ImageContainer>
                                        ))}
                                      </ImageGrid>
                                    </CategoryTypeSection>
                                  ))}
                              </div>
                            ) : (
                              <RecordsList>
                                {type.records.map((record) => (
                                  <WellnessRecordItem key={record.id}>
                                    <RecordHeader>
                                      <RecordDate>
                                        {new Date(
                                          record.actionDate
                                        ).toLocaleDateString()}
                                      </RecordDate>
                                      {record.vetName && (
                                        <RecordVet>
                                          Vet: {record.vetName}
                                        </RecordVet>
                                      )}
                                    </RecordHeader>
                                    {record.recordValue && (
                                      <RecordValue>
                                        {record.recordValue}
                                        {record.unitOfMeasure &&
                                          ` ${record.unitOfMeasure}`}
                                      </RecordValue>
                                    )}
                                    {record.nextReminderDate && (
                                      <ReminderContainer>
                                        <ReminderIcon>⏰</ReminderIcon>
                                        <ReminderText>
                                          Next Reminder Date Due:{" "}
                                          {new Date(
                                            record.nextReminderDate
                                          ).toLocaleDateString()}
                                        </ReminderText>
                                      </ReminderContainer>
                                    )}
                                    {record.notes && (
                                      <RecordNotes>{record.notes}</RecordNotes>
                                    )}
                                  </WellnessRecordItem>
                                ))}
                              </RecordsList>
                            )}
                          </CategoryTypeSection>
                        )
                    )}
                  </>
                )}
              </ScrollableContent>
            )}

            {selectedVisit && (
              <EditVisit
                visit={selectedVisit}
                visitId={selectedVisit.id}
                onClose={() => setSelectedVisit(null)}
                authToken={authToken}
              />
            )}
          </>
        )}
      </Content>
    </Overlay>
  );
};

// Add styled component for error message
const ErrorMessage = styled.div`
  color: #ff4d4f;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  padding: 12px;
  border-radius: 4px;
  margin: 10px 0;
  text-align: center;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px 20px 5px 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  height: 93%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;

  h2 {
    flex-grow: 1;
    text-align: center;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.2rem;
    }
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  color: #5951fc;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 3px;
  }
`;

const PetType = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  margin: 2px 0;
`;

const PetTypeIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  filter: invert(37%) sepia(14%) saturate(6889%) hue-rotate(232deg)
    brightness(99%) contrast(98%);
  opacity: 0.5;
`;

const PetProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 10px;
  }
`;

const PetImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

const PetInfo = styled.div`
  flex: 1;

  h3 {
    margin: 0 0 10px 0;
  }

  p {
    margin: 5px 0;
    color: #666;
  }

  @media (max-width: 768px) {
    h3 {
      margin: 0 0 4px 0;
      font-size: 0.9rem;
    }

    p {
      margin: 2px 0;
      font-size: 0.75rem;
    }
  }
`;

const PetAge = styled.span`
  font-size: 0.8em;
  color: #666;
  margin-left: 8px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 20px 0;

  @media (max-width: 768px) {
    gap: 8px;
    margin: 12px 0;
  }
`;

const Tab = styled.button`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: ${(props) =>
    props.isActive ? "rgba(89, 81, 252, 0.1)" : "none"};
  border: none;
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#5951fc" : "#666")};
  font-size: 16px;
  border-radius: 4px;
  transition: all 0.2s ease;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: #5951fc;
    background: rgba(89, 81, 252, 0.05);
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 12px;
    min-width: 0;
    flex: 1;
    text-align: center;

    svg {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }
  }
`;

const Divider = styled.span`
  color: #666;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 5px;
  height: calc(100% - 430px);

  @media (max-width: 768px) {
    padding: 12px;
    height: calc(100% - 355px);
  }
`;

const SectionTitle = styled.h3`
  color: #666;
  margin: 10px 0 10px;
  font-size: 16px;
`;

const WellnessRecordItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 6px;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 4px;
  }
`;

const RecordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  padding: 16px;
`;

const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageNotes = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  font-size: 12px;
`;

const ContentArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const LoadingImage = styled.img`
  width: 150px;
  height: auto;
`;

const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background: white;
  border-radius: 8px;

  @media (max-width: 768px) {
    gap: 8px;
    padding: 12px;
  }
`;

const InfoRowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 6px;
  }
`;

const InfoCard = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 6px;
  border: none;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    gap: 8px;
    padding: 8px;
  }

  &:hover {
    background: #f3f4ff;
    transform: translateY(-1px);
  }
`;

const InfoIcon = styled.div`
  color: #5951fc;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
  }

  svg {
    width: 16px;
    height: 16px;

    @media (max-width: 768px) {
      width: 14px;
      height: 14px;
    }
  }
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  @media (max-width: 768px) {
    gap: 1px;
  }
`;

const InfoLabel = styled.span`
  font-size: 11px;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @media (max-width: 768px) {
    font-size: 10px;
    letter-spacing: 0.3px;
  }
`;

const InfoValue = styled.span`
  color: #333;
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: white;
  border-radius: 6px;

  img {
    width: 100px;
    height: auto;
  }
`;

const CategoryTypeSection = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
`;

const TypeHeader = styled.div`
  margin-bottom: 12px;

  h4 {
    color: #333;
    margin: 0;
    font-size: 16px;
  }
`;

const RecordsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RecordDate = styled.span`
  font-size: 14px;
  color: #666;
  min-width: 100px;

  @media (max-width: 768px) {
    font-size: 12px;
    min-width: auto;
  }
`;

const RecordValue = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #333;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const RecordNotes = styled.span`
  font-size: 14px;
  color: #666;
  font-style: italic;
  word-break: break-word;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const RecordVet = styled.span`
  font-size: 14px;
  color: #5951fc;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ReminderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background: rgba(89, 81, 252, 0.05);
  border-radius: 4px;
  margin-top: 4px;

  @media (max-width: 768px) {
    padding: 6px;
    gap: 6px;
  }
`;

const ReminderIcon = styled.span`
  font-size: 14px;
`;

const ReminderText = styled.span`
  font-size: 13px;
  color: #5951fc;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const SubTypeTabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 0 16px 16px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 0 8px 12px;
    gap: 6px;

    /* These properties ensure proper centering on mobile */
    width: 100%;
    justify-content: center;
    flex-wrap: nowrap;

    /* This ensures the content is centered even when scrolling */
    &::before,
    &::after {
      content: "";
      flex: 0 0 8px;
    }
  }
`;

const SubTypeTab = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background: ${(props) => (props.isActive ? "#5951fc" : "#f0f0f0")};
  color: ${(props) => (props.isActive ? "white" : "#666")};
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 16px;
  }

  &:hover {
    background: ${(props) => (props.isActive ? "#5951fc" : "#e0e0e0")};
  }
`;

const SectionDivider = styled.hr`
  border: none;
  border-top: 1px solid #eee;
  margin: 20px 0;
`;

const VisitsSection = styled.div`
  margin-top: 10px;
`;

const VisitsHeader = styled.div`
  margin-bottom: 16px;
`;

const VisitCount = styled.span`
  font-size: 13px;
  color: #666;
  margin-left: 8px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const VisitsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const VisitCard = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 6px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #f3f4ff;
    transform: translateY(-1px);
  }

  @media (max-width: 768px) {
    padding: 8px;
    gap: 8px;
  }
`;

const VisitIcon = styled.div`
  color: #5951fc;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 6px;

  svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const VisitInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const VisitDate = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #333;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const VisitVet = styled.span`
  font-size: 13px;
  color: #666;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;

  @media (max-width: 768px) {
    gap: 8px;
    margin-top: 16px;
  }
`;

const PaginationButton = styled.button`
  padding: 6px 12px;
  border: 1px solid #5951fc;
  border-radius: 4px;
  background: white;
  color: #5951fc;
  cursor: pointer;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 12px;
  }

  &:disabled {
    border-color: #ddd;
    color: #999;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: #5951fc;
    color: white;
  }
`;

const PageInfo = styled.span`
  font-size: 14px;
  color: #666;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const NoVisits = styled.div`
  text-align: center;
  color: #666;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 6px;
  font-size: 14px;
`;

export default VetPetProfile;
