import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import styled from "styled-components";
import { getVetReminders } from "../connectors/Vet";
import { UserContext } from "../context/UserContext";
import { FiImage } from "react-icons/fi";
import loadingCat from "../assets/loading_cat.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Notifications = () => {
  const [reminders, setReminders] = useState({ reminders: [], totalCount: 0 });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const limit = 4;
  const { user } = useContext(UserContext);
  const token = user?.token;
  const [isPulling, setIsPulling] = useState(false);
  const [pullStartY, setPullStartY] = useState(0);
  const scrollContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchReminders = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    try {
      const offset = currentPage;
      const formattedDate = selectedDate.toISOString().split("T")[0];
      const data = await getVetReminders(formattedDate, offset, limit, token);
      setReminders(data);
      setErrorMessage("");
    } catch (error) {
      console.error("Error fetching vet reminders:", error);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, selectedDate, token, limit]);

  useEffect(() => {
    fetchReminders();
  }, [fetchReminders]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    if (reminders.reminders.length === limit) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleTouchStart = (e) => {
    // Only enable pull-to-refresh when at the top of the container
    if (scrollContainerRef.current.scrollTop === 0) {
      setPullStartY(e.touches[0].clientY);
    }
  };

  const handleTouchMove = (e) => {
    if (pullStartY === 0) return;

    const pullDistance = e.touches[0].clientY - pullStartY;
    if (pullDistance > 50) {
      setIsPulling(true);
    }
  };

  const handleTouchEnd = async () => {
    if (isPulling) {
      // Reset states
      setIsPulling(false);
      setPullStartY(0);

      // Reset to first page and show loading
      setCurrentPage(1);
      setIsLoading(true);

      // Refresh data
      try {
        const formattedDate = selectedDate.toISOString().split("T")[0];
        const data = await getVetReminders(formattedDate, 1, limit, token);
        setReminders(data);
        setErrorMessage("");
      } catch (error) {
        console.error("Error refreshing vet reminders:", error);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Container>
      <h1>Notifications</h1>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <ButtonContainer>
        <DatePickerWrapper>
          <StyledDatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="MMMM d, yyyy"
          />
        </DatePickerWrapper>
      </ButtonContainer>
      <TotalCount>Total Notifications: {reminders.count}</TotalCount>
      <ScrollableContainer
        ref={scrollContainerRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {isPulling && (
          <RefreshIndicator>Release to refresh...</RefreshIndicator>
        )}
        {isLoading ? (
          <LoadingContainer>
            <LoadingImage src={loadingCat} alt="Loading..." />
          </LoadingContainer>
        ) : (
          <StyledList>
            {reminders?.reminders?.map((reminder) => (
              <VisitCard key={reminder.id} style={{ cursor: "pointer" }}>
                <ImageSection>
                  <PetImage>
                    {reminder.petImage ===
                    process.env.REACT_APP_BLOB_STORAGE_URL ? (
                      <FiImage />
                    ) : (
                      <img src={reminder.petImage} alt={reminder.petName} />
                    )}
                  </PetImage>
                </ImageSection>
                <VisitInfo>
                  <PetNameContainer>
                    <PetName>{reminder.petName}</PetName>
                    <PetAge>{reminder.age} years</PetAge>
                  </PetNameContainer>
                  <PetType>
                    {reminder.petTypeImage && (
                      <PetTypeIcon
                        src={reminder.petTypeImage}
                        alt={reminder.petType}
                      />
                    )}
                    {reminder.petType}
                  </PetType>
                  <PetBreed>{reminder.petBreed}</PetBreed>
                  <OwnerInfo>{reminder.ownerNameForVet}</OwnerInfo>
                  <OwnerInfo>{reminder.ownerPhoneForVet}</OwnerInfo>
                  <VisitDate>
                    <strong>Reminder Date Due:</strong>{" "}
                    {new Date(reminder.nextReminderDate).toLocaleDateString(
                      undefined,
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  </VisitDate>
                  <VisitDate>
                    <strong>Record Value:</strong> {reminder.recordValue}
                  </VisitDate>
                </VisitInfo>
              </VisitCard>
            ))}
          </StyledList>
        )}
      </ScrollableContainer>
      <PaginationContainer>
        <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </PaginationButton>
        <PageNumber>Page {currentPage}</PageNumber>
        <PaginationButton
          onClick={handleNextPage}
          disabled={reminders.reminders.length < limit}
        >
          Next
        </PaginationButton>
      </PaginationContainer>
    </Container>
  );
};

const ButtonContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  margin-bottom: 20px;
  height: 90%;
  display: flex;
  flex-direction: column;
  h1 {
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 80px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 8px;
  flex-shrink: 0; // Prevent pagination from shrinking

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const PaginationButton = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #5951fc;
  color: white;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 6px;

  &:before {
    content: ${(props) =>
      props.children === "Previous"
        ? '"←"'
        : props.children === "Next"
        ? '"→"'
        : '""'};
    font-size: 16px;
    ${(props) => props.children === "Next" && "order: 1;"}
  }

  &:disabled {
    background-color: #f5f5f5;
    color: #bdbdbd;
    border-color: #e0e0e0;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #5951fc;
    color: white;
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 11px;

    &:before {
      font-size: 12px;
    }
  }
`;

const PageNumber = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #555;
  background-color: #f8f9fa;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 11px;
  }
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  min-height: 0; // This is important for flex containers
  overscroll-behavior-y: contain;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const VisitCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin: 8px 0;
  display: flex;
  gap: 15px;
  position: relative;

  @media (max-width: 768px) {
    padding: 8px;
    gap: 8px;
    margin: 4px 0;
  }
`;

const ImageSection = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PetImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    color: #999;
    width: 40%;
    height: 40%;
  }
`;

const VisitInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: 768px) {
    gap: 2px;
  }
`;

const PetName = styled.span`
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const PetType = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #666;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const PetBreed = styled.span`
  color: #007bff;
  font-size: 13px;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const OwnerInfo = styled.span`
  font-size: 13px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const VisitDate = styled.span`
  font-size: 13px;
  color: #666;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const RefreshIndicator = styled.div`
  text-align: center;
  padding: 10px;
  color: #666;
  background-color: #f5f5f5;
`;

const DatePickerWrapper = styled.div`
  width: 100%;
  max-width: 200px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  color: #333;

  &:focus {
    outline: none;
    border-color: #5951fc;
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
    font-size: 12px;
  }
`;

const PetTypeIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  filter: invert(37%) sepia(14%) saturate(6889%) hue-rotate(232deg)
    brightness(99%) contrast(98%);
  opacity: 0.5;
`;

const PetNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PetAge = styled.span`
  font-size: 14px;
  color: #666;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const LoadingImage = styled.img`
  width: 150px;
  height: auto;
  object-fit: contain;
`;

const TotalCount = styled.div`
  color: #666;
  font-size: 14px;
  margin: 8px 0;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export default Notifications;
