import React, { useEffect, useState, useContext, useCallback } from "react";
import styled from "styled-components";
import { getVetPets } from "../connectors/Vet";
import { UserContext } from "../context/UserContext";
import { FiTrash2, FiImage, FiEdit } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import VetPetProfile from "../components/VetPetProfile";
import loadingCat from "../assets/loading_cat.gif";
import { deleteVetPet } from "../connectors/Vet";
import AddVetPet from "../components/AddVetPet";
import EditVetPet from "../components/EditVetPet";

const VetPets = () => {
  const [pets, setPets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const limit = 4;
  const { user } = useContext(UserContext);
  const token = user?.token;
  const [isLoading, setIsLoading] = useState(false);
  const [totalPets, setTotalPets] = useState(0);
  const [showAddPet, setShowAddPet] = useState(false);
  const [editingPet, setEditingPet] = useState(null);
  const [selectedPet, setSelectedPet] = useState(null);

  const fetchPets = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    try {
      const data = await getVetPets(currentPage, limit, searchTerm, token);
      setPets(data.pets);
      setTotalPets(data.count);
      setErrorMessage("");
    } catch (error) {
      console.error("Error fetching vet pets:", error);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, searchTerm, token]);

  useEffect(() => {
    fetchPets();
  }, [fetchPets]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    if (pets.length === limit) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleDeletePet = (petId) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomAlertContainer>
          <AlertTitle>Confirm Deletion</AlertTitle>
          <AlertMessage>Are you sure you want to delete this pet?</AlertMessage>
          <AlertButtonContainer>
            <AlertButton
              onClick={async () => {
                try {
                  await deleteVetPet(petId, token);
                  // Refresh the visits list after successful deletion
                  const data = await getVetPets(
                    currentPage,
                    limit,
                    searchTerm,
                    token
                  );
                  setPets(data.pets);
                  setTotalPets(data.count);
                  setErrorMessage("");
                  onClose();
                } catch (error) {
                  console.error("Error deleting visit:", error);
                  setErrorMessage("Failed to delete visit: " + error.message);
                  onClose();
                }
              }}
            >
              Yes
            </AlertButton>
            <AlertButton onClick={onClose}>No</AlertButton>
          </AlertButtonContainer>
        </CustomAlertContainer>
      ),
    });
  };

  const handlePetClick = (pet) => {
    setSelectedPet(pet);
  };

  return (
    <Container>
      <h1>Vet Pets</h1>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <ButtonContainer>
        <SearchContainer>
          <SearchInput
            type="text"
            placeholder="Search pets by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchContainer>
        <NewPetButton onClick={() => setShowAddPet(true)}>
          + Add Pet
        </NewPetButton>
      </ButtonContainer>
      <TotalCount>Total Pets: {totalPets}</TotalCount>
      <ScrollableContainer>
        {isLoading ? (
          <LoadingContainer>
            <LoadingImage src={loadingCat} alt="Loading..." />
          </LoadingContainer>
        ) : (
          <StyledList>
            {pets.map((pet) => (
              <PetCard
                key={pet.ID}
                onClick={() => handlePetClick(pet)}
                style={{ cursor: "pointer" }}
              >
                <ImageSection>
                  <PetImage>
                    {pet.petImage === process.env.REACT_APP_BLOB_STORAGE_URL ? (
                      <FiImage />
                    ) : (
                      <img src={pet.petImage} alt={pet.Name} />
                    )}
                  </PetImage>
                </ImageSection>
                <PetInfo>
                  <PetNameContainer>
                    <PetName>{pet.Name}</PetName>
                    <PetAge>{pet.age} years</PetAge>
                  </PetNameContainer>
                  <PetType>
                    {pet.petTypeImage && (
                      <PetTypeIcon src={pet.petTypeImage} alt={pet.petType} />
                    )}
                    {pet.petType}
                  </PetType>
                  <PetBreed>{pet.petBreed}</PetBreed>
                  <OwnerInfo>{pet.OwnerNameForVet}</OwnerInfo>
                  <OwnerInfo>{pet.OwnerPhoneForVet}</OwnerInfo>
                  <OwnerInfo>{pet.OwnerEmailForVet}</OwnerInfo>
                </PetInfo>
                <ActionButtons>
                  <ActionButton
                    variant="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeletePet(pet.ID);
                    }}
                  >
                    <FiTrash2 size={14} />
                    Delete
                  </ActionButton>
                  <ActionButton
                    variant="edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingPet(pet);
                    }}
                  >
                    <FiEdit size={14} />
                    Edit
                  </ActionButton>
                </ActionButtons>
              </PetCard>
            ))}
          </StyledList>
        )}
      </ScrollableContainer>
      <PaginationContainer>
        <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </PaginationButton>
        <PageNumber>Page {currentPage}</PageNumber>
        <PaginationButton
          onClick={handleNextPage}
          disabled={pets.length < limit}
        >
          Next
        </PaginationButton>
      </PaginationContainer>
      {showAddPet && (
        <AddVetPet
          onClose={() => {
            setShowAddPet(false);
            fetchPets();
          }}
          onSuccess={() => {
            setShowAddPet(false);
            fetchPets();
          }}
        />
      )}
      {editingPet && (
        <EditVetPet
          pet={editingPet}
          onClose={() => {
            setEditingPet(null);
            fetchPets();
          }}
          onSuccess={() => {
            setEditingPet(null);
            fetchPets();
          }}
        />
      )}
      {selectedPet && (
        <VetPetProfile
          pet={selectedPet}
          onClose={() => setSelectedPet(null)}
          authToken={token}
        />
      )}
    </Container>
  );
};

const ButtonContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const Container = styled.div`
  margin-bottom: 20px;
  height: 90%;
  display: flex;
  flex-direction: column;
  h1 {
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 80px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 8px;
  flex-shrink: 0; // Prevent pagination from shrinking

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const PaginationButton = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #5951fc;
  color: white;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 6px;

  &:before {
    content: ${(props) =>
      props.children === "Previous"
        ? '"←"'
        : props.children === "Next"
        ? '"→"'
        : '""'};
    font-size: 16px;
    ${(props) => props.children === "Next" && "order: 1;"}
  }

  &:disabled {
    background-color: #f5f5f5;
    color: #bdbdbd;
    border-color: #e0e0e0;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #5951fc;
    color: white;
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 11px;

    &:before {
      font-size: 12px;
    }
  }
`;

const PageNumber = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #555;
  background-color: #f8f9fa;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 11px;
  }
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  min-height: 0; // This is important for flex containers
  overscroll-behavior-y: contain;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PetCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin: 8px 0;
  display: flex;
  gap: 15px;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 10px;
  }
`;

const ImageSection = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PetImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    color: #999;
    width: 40%;
    height: 40%;
  }
`;

const PetInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PetName = styled.span`
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const PetType = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #666;
`;

const PetBreed = styled.span`
  color: #007bff;
  font-size: 13px;
`;

const OwnerInfo = styled.span`
  font-size: 13px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ActionButtons = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ActionButton = styled.button`
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  background-color: transparent;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    padding: 2px 4px;
    font-size: 10px;
    gap: 2px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  ${({ variant }) =>
    variant === "edit" &&
    `
        border: 1px solid #007bff;
        color: #007bff;
        &:hover {
            background-color: rgba(0, 123, 255, 0.1);
        }
    `}

  ${({ variant }) =>
    variant === "delete" &&
    `
        border: 1px solid #dc3545;
        color: #dc3545;
        &:hover {
            background-color: rgba(220, 53, 69, 0.1);
        }
    `}
`;

const CustomAlertContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const AlertTitle = styled.h1`
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
`;

const AlertMessage = styled.p`
  margin-bottom: 20px;
  color: #666;
`;

const AlertButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const AlertButton = styled.button`
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #5951fc;
  color: white;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

const TotalCount = styled.div`
  font-size: 14px;
  color: #666;
`;

const PetTypeIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  filter: invert(37%) sepia(14%) saturate(6889%) hue-rotate(232deg)
    brightness(99%) contrast(98%);
  opacity: 0.5;
`;

const PetNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PetAge = styled.span`
  font-size: 14px;
  color: #666;
  font-weight: normal;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const LoadingImage = styled.img`
  width: 150px;
  height: auto;
  object-fit: contain;
`;

const SearchContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;

  &:focus {
    border-color: #5951fc;
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
    font-size: 12px;
  }
`;

const NewPetButton = styled.button`
  padding: 10px 15px;
  margin-right: 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #5951fc;
  color: white;
  white-space: nowrap;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
    font-size: 12px;
  }
`;

export default VetPets;
