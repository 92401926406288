import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/logo.png';
import loading_cat from '../../assets/loading_cat.gif'; // Adjust the path as needed
import { getPetSitters } from '../../connectors/Pet';
import { getCountries } from '../../connectors/Authentication';

const PetSitters = () => {
  const navigate = useNavigate(); // Initialize the navigate hook
  const [petSitters, setPetSitters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null);
  const limit = 4; // Number of items per page
  const [countries, setCountries] = useState([]); // State for countries
  const [selectedCountry, setSelectedCountry] = useState(''); // State for selected country

  const fetchPetSitters = useCallback(async (page) => {
    setLoading(true);
    const offset = page;

    try {
      const data = await getPetSitters(offset, limit, selectedCountry);
      setPetSitters(data);
      setTotalPages(Math.ceil(data.totalCount / limit));
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, [limit, selectedCountry]);

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      setCountries(response);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchPetSitters(currentPage);
  }, [currentPage, fetchPetSitters]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePetSitterClick = (sitter) => {
    navigate(`/pet-sitter/${sitter.ID}`, { state: { sitter } });
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    // Optionally, you can filter pet sitters based on the selected country here
  };

  if (loading) {
    return (
      <LoadingContainer>
        <img src={loading_cat} alt="Loading..." />
      </LoadingContainer>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Container>
      <Header>
        <Link to="/">
          <BackButton onClick={() => { navigate('/'); }}>&larr; Back</BackButton>
        </Link>
        <Title>Available Pet Sitters</Title>
        <LogoContainer>
          <Logo onClick={() => { navigate('/'); }} src={logo} alt="Pawers Logo" />
        </LogoContainer>
      </Header>
      <CountryDropdown value={selectedCountry} onChange={handleCountryChange}>
        <option value="">Select a country</option>
        {countries.map((country) => (
          <option key={country.id} value={country.id}>
            {country.title}
          </option>
        ))}
      </CountryDropdown>
      {loading && <LoadingContainer>Loading...</LoadingContainer>} {/* Loading indicator */}
      {error && <Error>Error: {error}</Error>}
      <PetSittersWrapper>
        {petSitters.map((sitter) => (
          <PetSitterCard key={sitter.ID} onClick={() => handlePetSitterClick(sitter)}>
            <PetSitterImage src={sitter.image} alt={sitter.name} />
            <PetSitterInfo>
              <PetSitterName>{sitter.name}</PetSitterName>
              <PetSitterSpecialty>{sitter.speciality}</PetSitterSpecialty>
              <PetSitterAddress>{sitter.address}, {sitter.city}, {sitter.country}</PetSitterAddress>
            </PetSitterInfo>
          </PetSitterCard>
        ))}
      </PetSittersWrapper>
      <Pagination>
        <PaginationButton
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </PaginationButton>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <PaginationButton
            key={page}
            onClick={() => handlePageChange(page)}
            disabled={page === currentPage}
          >
            {page}
          </PaginationButton>
        ))}
        <PaginationButton
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={petSitters.length === 0}
        >
          Next
        </PaginationButton>
      </Pagination>
    </Container>
  );
};

export default PetSitters;

// CSS Styled Components
const Container = styled.div`
  background-color: #f5f7fc; 
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 50px;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  font-size: 1.5em;
  color: #333;
  margin: 50px 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
`;

const Error = styled.div`
  color: red;
  text-align: center;
`;

const PetSittersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const PetSitterCard = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const PetSitterImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const PetSitterInfo = styled.div`
  padding: 10px;
`;

const PetSitterName = styled.h2`
  font-size: 1.5em;
  color: #5951fc;
  margin-bottom: 10px;
`;

const PetSitterSpecialty = styled.p`
  font-size: 1em;
  color: #333;
  margin-bottom: 5px;
`;

const PetSitterAddress = styled.p`
  font-size: 1em;
  color: #333;
  margin-bottom: 5px;
`;


const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #5951fc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #4640cc;
  }
  
  &:disabled {
    background-color: #d0d0d0;
    cursor: not-allowed;
  }
`;

const CountryDropdown = styled.select`
  margin: 20px 0;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  background: linear-gradient(145deg, #f0f0f0, #ffffff);
  color: #333;
  width: 100%;
  max-width: 300px;
  box-shadow: 4px 4px 8px #e0e0e0, -4px -4px 8px #e0e0e0;
  appearance: none;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;

  &:focus {
    box-shadow: inset 4px 4px 8px #e0e0e0, inset -4px -4px 8px #e0e0e0;
  }

  &:hover {
    border-color: #5951fc;
    box-shadow: 0 0 5px rgba(89, 81, 252, 0.3);
  }
`;
