import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import {
  getPetBreeds,
  getVetPetDetails,
  updateVetPet,
} from "../connectors/Vet"; // Adjust path as needed
import { FiUpload } from "react-icons/fi";
import { UserContext } from "../context/UserContext";
import loadingCat from "../assets/loading_cat.gif";

const EditVetPet = ({ pet, onClose }) => {
  const { user } = useContext(UserContext);

  const [formData, setFormData] = useState({
    name: "",
    type: "",
    breed: "",
    breedId: null,
    ownerName: "",
    ownerPhone: "",
    ownerEmail: "",
    bloodType: "",
    microchipId: "",
    gender: "",
    sterilized: "unknown",
    image: null,
    dateOfBirth: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [breeds, setBreeds] = useState([]);
  const [isBreedDropdownOpen, setIsBreedDropdownOpen] = useState(false);
  const [breedSearch, setBreedSearch] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (e.g., max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        alert("File size should not exceed 5MB");
        return;
      }

      // Check file type
      if (!file.type.startsWith("image/")) {
        alert("Only image files are allowed");
        return;
      }

      setFormData((prev) => ({
        ...prev,
        image: file,
      }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const typeDropdown = document.getElementById("pet-type-dropdown");
      const breedDropdown = document.getElementById("pet-breed-dropdown");

      if (typeDropdown && !typeDropdown.contains(event.target)) {
        setIsTypeDropdownOpen(false);
      }

      if (breedDropdown && !breedDropdown.contains(event.target)) {
        setIsBreedDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchBreeds = useCallback(
    async (petType) => {
      setLoading(true);
      setApiError(null);
      try {
        const selectedPetType = user.pettypes.find(
          (pt) => pt.Title === petType
        );
        if (selectedPetType) {
          const breedsData = await getPetBreeds(selectedPetType.ID, user.token);
          setBreeds(breedsData);
        }
      } catch (error) {
        console.error("Error fetching breeds:", error);
        setApiError(error.message || "Failed to fetch breeds");
      } finally {
        setLoading(false);
      }
    },
    [user.pettypes, user.token]
  );

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Pet name is required";
    if (!formData.type) newErrors.type = "Pet type is required";
    if (!formData.breed) newErrors.breed = "Breed is required";
    if (!formData.ownerName) newErrors.ownerName = "Owner name is required";
    if (!formData.ownerPhone) newErrors.ownerPhone = "Owner phone is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.dateOfBirth)
      newErrors.dateOfBirth = "Date of birth is required";
    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      setApiError(null);
      try {
        const selectedPetType = user.pettypes.find(
          (pt) => pt.Title === formData.type
        );

        const petData = {
          petProfileId: pet.ID,
          petName: formData.name,
          petDateOfBirth: formData.dateOfBirth,
          petGenderId: parseInt(formData.gender),
          petBreedId: formData.breedId,
          petTypeId: selectedPetType?.ID,
          isSterilized:
            formData.sterilized === "true"
              ? true
              : formData.sterilized === "false"
              ? false
              : null,
          microChipId: formData.microchipId,
          bloodType: formData.bloodType,
          ownerNameForVet: formData.ownerName,
          ownerPhoneForVet: formData.ownerPhone,
          ownerEmailForVet: formData.ownerEmail,
        };

        const pictures = imagePreview
          ? [
              {
                file:
                  formData.image instanceof File
                    ? formData.image
                    : await fetchImageAsFile(imagePreview, "pet_image.jpg"),
                notes: "",
              },
            ]
          : [];

        await updateVetPet(petData, pictures, user.token);
        onClose();
      } catch (error) {
        console.error("Error updating pet:", error);
        setApiError(error.message || "Failed to update pet");
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchImageAsFile = async (imageUrl, imageName) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], imageName, { type: blob.type });
  };

  useEffect(() => {
    const fetchPetDetails = async () => {
      setLoading(true);
      setApiError(null);
      try {
        const petDetails = await getVetPetDetails(pet.ID, user.token);

        // Fetch breeds for the pet type before setting form data
        const selectedPetType = user.pettypes.find(
          (pt) => pt.ID === petDetails.petTypeId
        );
        if (selectedPetType) {
          await fetchBreeds(selectedPetType.Title);
        }

        setFormData({
          name: petDetails.petName,
          type: selectedPetType?.Title || "",
          breed: petDetails.petBreed,
          breedId: petDetails.petBreedId,
          ownerName: petDetails.ownerNameForVet,
          ownerPhone: petDetails.ownerPhoneForVet,
          ownerEmail: petDetails.ownerEmailForVet,
          bloodType: petDetails.bloodType || "",
          microchipId: petDetails.microChipId || "",
          gender: petDetails.petGenderId?.toString() || "",
          sterilized:
            petDetails.isSterilized === true
              ? "true"
              : petDetails.isSterilized === false
              ? "false"
              : "unknown",
          dateOfBirth: petDetails.dob || "",
        });

        if (petDetails.petImage !== process.env.REACT_APP_BLOB_STORAGE_URL) {
          setImagePreview(petDetails.petImage);
        }
      } catch (error) {
        console.error("Error fetching pet details:", error);
        setApiError(error.message || "Failed to fetch pet details");
      } finally {
        setLoading(false);
      }
    };

    if (pet?.ID) {
      fetchPetDetails();
    }
  }, [pet?.ID, user.token, user.pettypes, fetchBreeds]);

  return (
    <Overlay>
      <Content>
        {apiError && <ErrorBanner>{apiError}</ErrorBanner>}
        {loading && (
          <LoadingContainer>
            <img src={loadingCat} alt="Loading..." />
          </LoadingContainer>
        )}
        {!loading && (
          <>
            <Header>
              <h2>Edit Pet</h2>
              <CloseButton onClick={onClose}>×</CloseButton>
            </Header>
            <FormContainer>
              <ImageUploadContainer>
                <ImagePreview
                  onClick={() => document.getElementById("imageUpload").click()}
                >
                  {imagePreview ? (
                    <PreviewImage src={imagePreview} alt="Pet preview" />
                  ) : (
                    <UploadIcon>
                      <FiUpload size={40} />
                    </UploadIcon>
                  )}
                </ImagePreview>
                <ImageInput
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  id="imageUpload"
                />
              </ImageUploadContainer>

              <FormGrid>
                <FormField>
                  <Label>Pet Name</Label>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter pet name"
                    hasError={errors.name}
                  />
                  {errors.name && <ErrorText>{errors.name}</ErrorText>}
                </FormField>

                <FormField>
                  <Label>Pet Type</Label>
                  <CustomSelect
                    onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
                    id="pet-type-dropdown"
                    hasError={errors.type}
                  >
                    <SelectedOption>
                      {formData.type ? (
                        <>
                          {user.pettypes.find(
                            (pt) => pt.Title === formData.type
                          ) && (
                            <PetTypeIcon
                              src={
                                user.pettypes.find(
                                  (pt) => pt.Title === formData.type
                                ).HostLink +
                                user.pettypes.find(
                                  (pt) => pt.Title === formData.type
                                ).Image
                              }
                              alt={formData.type}
                            />
                          )}
                          {formData.type}
                        </>
                      ) : (
                        "Select type"
                      )}
                    </SelectedOption>
                    {isTypeDropdownOpen && (
                      <OptionsContainer>
                        {user.pettypes &&
                          user.pettypes.map((petType) => (
                            <Option
                              key={petType.ID}
                              onClick={async (e) => {
                                e.stopPropagation();
                                handleInputChange({
                                  target: {
                                    name: "type",
                                    value: petType.Title,
                                  },
                                });
                                setFormData((prev) => ({
                                  ...prev,
                                  breed: "",
                                }));
                                await fetchBreeds(petType.Title);
                                setIsTypeDropdownOpen(false);
                              }}
                            >
                              <PetTypeIcon
                                src={petType.HostLink + petType.Image}
                                alt={petType.Title}
                              />
                              {petType.Title}
                            </Option>
                          ))}
                      </OptionsContainer>
                    )}
                  </CustomSelect>
                  {errors.type && <ErrorText>{errors.type}</ErrorText>}
                </FormField>

                <FormField>
                  <Label>
                    Breed
                    <HintText>(If Mix: Breed1 X Breed2)</HintText>
                  </Label>
                  <CustomSelect
                    onClick={() => setIsBreedDropdownOpen(!isBreedDropdownOpen)}
                    id="pet-breed-dropdown"
                    hasError={errors.breed}
                  >
                    <SelectedOption>
                      {formData.breed ? formData.breed : "Select breed"}
                    </SelectedOption>
                    {isBreedDropdownOpen && (
                      <OptionsContainer>
                        <SearchInput
                          type="text"
                          placeholder="Search breeds..."
                          value={breedSearch}
                          onChange={(e) => {
                            e.stopPropagation();
                            setBreedSearch(e.target.value);
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                        {breeds
                          .filter((breed) => {
                            const searchTerms = breedSearch
                              .toLowerCase()
                              .split(" ");
                            return searchTerms.every((term) =>
                              breed.title.toLowerCase().includes(term)
                            );
                          })
                          .map((breed) => (
                            <Option
                              key={breed.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleInputChange({
                                  target: {
                                    name: "breed",
                                    value: breed.title,
                                  },
                                });
                                setFormData((prev) => ({
                                  ...prev,
                                  breedId: breed.id,
                                }));
                                setIsBreedDropdownOpen(false);
                                setBreedSearch("");
                              }}
                            >
                              {breed.title}
                            </Option>
                          ))}
                      </OptionsContainer>
                    )}
                  </CustomSelect>
                  {errors.breed && <ErrorText>{errors.breed}</ErrorText>}
                  <SwitchContainer>
                    <SwitchLabel>
                      <Switch
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked && breeds.length > 0) {
                            setFormData((prev) => ({
                              ...prev,
                              breed: breeds[0].title,
                              breedId: breeds[0].id,
                            }));
                          }
                        }}
                      />
                      <SwitchText>Unknown</SwitchText>
                    </SwitchLabel>
                  </SwitchContainer>
                </FormField>

                <FormField>
                  <Label>Date of Birth</Label>
                  <Input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                    max={new Date().toISOString().split("T")[0]}
                    hasError={errors.dateOfBirth}
                  />
                  {errors.dateOfBirth && (
                    <ErrorText>{errors.dateOfBirth}</ErrorText>
                  )}
                </FormField>

                <FormField>
                  <Label>Owner Name</Label>
                  <Input
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={handleInputChange}
                    placeholder="Enter owner name"
                    hasError={errors.ownerName}
                  />
                  {errors.ownerName && (
                    <ErrorText>{errors.ownerName}</ErrorText>
                  )}
                </FormField>

                <FormField>
                  <Label>Owner Phone</Label>
                  <Input
                    name="ownerPhone"
                    value={formData.ownerPhone}
                    onChange={handleInputChange}
                    placeholder="Enter phone number"
                    hasError={errors.ownerPhone}
                  />
                  {errors.ownerPhone && (
                    <ErrorText>{errors.ownerPhone}</ErrorText>
                  )}
                </FormField>

                <FormField>
                  <Label>
                    Owner Email<OptionalLabel>(Optional)</OptionalLabel>
                  </Label>
                  <Input
                    name="ownerEmail"
                    value={formData.ownerEmail}
                    onChange={handleInputChange}
                    placeholder="Enter email address"
                  />
                </FormField>

                <FormField>
                  <Label>Gender</Label>
                  <Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    hasError={errors.gender}
                  >
                    <option value="">Select gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </Select>
                  {errors.gender && <ErrorText>{errors.gender}</ErrorText>}
                </FormField>

                <FormField>
                  <Label>
                    Sterilized<OptionalLabel>(Optional)</OptionalLabel>
                  </Label>
                  <Select
                    name="sterilized"
                    value={formData.sterilized}
                    onChange={handleInputChange}
                  >
                    <option value="5">Unknown</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </FormField>

                <FormField>
                  <Label>
                    Blood Type<OptionalLabel>(Optional)</OptionalLabel>
                  </Label>
                  <Input
                    name="bloodType"
                    value={formData.bloodType}
                    onChange={handleInputChange}
                    placeholder="Enter blood type"
                  />
                </FormField>

                <FormField>
                  <Label>
                    Microchip ID<OptionalLabel>(Optional)</OptionalLabel>
                  </Label>
                  <Input
                    name="microchipId"
                    value={formData.microchipId}
                    onChange={handleInputChange}
                    placeholder="Enter microchip ID"
                  />
                </FormField>
              </FormGrid>

              <SubmitButton onClick={handleSubmit}>Save</SubmitButton>
            </FormContainer>
          </>
        )}
      </Content>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 85%;
  height: 93%;
  max-width: 1200px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    flex-grow: 1;
    text-align: center;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  color: #5951fc;
`;

const SearchInput = styled.input`
  width: calc(100% - 16px);
  margin: 8px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #5951fc;
  }
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const PetTypeIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  filter: invert(37%) sepia(14%) saturate(6889%) hue-rotate(232deg)
    brightness(99%) contrast(98%);
  opacity: 0.5;
`;

const FormContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const ImagePreview = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 75px;
  border: 2px dashed #5951fc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const UploadIcon = styled.div`
  color: #5951fc;
`;

const ImageInput = styled.input`
  display: none;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: 500;
`;

const OptionalLabel = styled.span`
  color: #5951fc;
  font-size: 12px;
  margin-left: 4px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    border-color: #5951fc;
    outline: none;
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;

  option {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  &:focus {
    border-color: #5951fc;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #5951fc;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
  &:hover {
    background-color: #4842e3;
  }
`;

const CustomSelect = styled.div`
  position: relative;
  cursor: pointer;
`;

const SelectedOption = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;

  &:hover {
    border-color: #5951fc;
  }
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Option = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const HintText = styled.span`
  color: #888;
  font-size: 12px;
  margin-left: 8px;
  font-weight: normal;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Switch = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  &:checked {
    background-color: #5951fc;
  }
`;

const SwitchText = styled.span`
  font-size: 14px;
  color: #333;
`;

const ErrorText = styled.span`
  color: #dc3545;
  font-size: 11px;
  margin-top: 2px;
`;

const ErrorBanner = styled.div`
  background-color: #ffebee;
  color: #c62828;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: center;
  font-size: 14px;
`;

export default EditVetPet;
