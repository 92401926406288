// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { vetLogin } from '../connectors/Vet'; // Import the vetLogin function

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null); // Initialize user state
    const [selectedUser, setSelectedUser] = useState(null); // Add state for selected user

    // Check local storage for user data on component mount
  useEffect(() => {
    console.log("Checking user");
    const checkUser = async () => {
      // Check for user session (e.g., from localStorage, cookies, or API call)
      const storedUser = localStorage.getItem('user'); // Example: retrieving user from localStorage
      if (storedUser) {
        setUser(JSON.parse(storedUser)); // Set user if found
      }
    };

    checkUser();
  }, []);

    // Add the login function
    const login = async (username, password) => {
        try {
            const userData = await vetLogin(username, password);
            setUser(userData); // Update user state with the returned user data
            localStorage.setItem('user', JSON.stringify(userData)); // Save user data to local storage
        } catch (error) {
            console.error("Login failed:", error); // Handle the error appropriately
            throw error;
        }
    };

    // Add selectUser function  
    const selectUser = (userChosen = null) => {
        if (!user.users.length) return;
        
        if (userChosen) {
            const selected = user.users.find(u => u.ID === userChosen.ID);
            setSelectedUser(selected || user.users[0]);
        } else {
            setSelectedUser(user.users[0]); // Default to first user
        }
    };

    return (
        <UserContext.Provider value={{ 
            user, 
            setUser, 
            login,
            selectedUser,
            selectUser 
        }}>
            {children}
        </UserContext.Provider>
    );
};