import { getRequest } from '../utils/request';


// getPrivacyPolicy endpoint
export const getPrivacyPolicy = async () => {
    const response = await getRequest('/api/LegalDocuments/GetPrivacyPolicy');
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// getTermsAndConditions endpoint
export const getTermsAndConditions = async () => {
    const response = await getRequest('/api/LegalDocuments/GetTermsAndConditions');
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};