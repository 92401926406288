import React from 'react';
import styled from 'styled-components';

const Button = ({ children, onClick, color, ...props }) => {
    return (
        <StyledButton onClick={onClick} color={color} {...props}>
            {children}
        </StyledButton>
    );
};

export default Button;

const StyledButton = styled.button`
    background-color: ${({ color }) => color || '#007bff'};
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: #0056b3;
    }
`;
