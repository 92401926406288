import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { getVetVisitDetails, saveVetVisit } from "../connectors/Vet"; // Adjust path as needed
import { FiImage, FiUpload, FiCamera } from "react-icons/fi";
import { FaUser } from "react-icons/fa"; // Imported icons
import { UserContext } from "../context/UserContext";
import loadingCat from "../assets/loading_cat.gif"; // Add this import at the top
import VetPetProfile from "./VetPetProfile";

const VACCINE_OPTIONS = [
  { value: "Rabies", label: "Rabies" },
  { value: "DHPP", label: "DHPP" },
  { value: "Bordetella", label: "Bordetella" },
  { value: "Leptospirosis", label: "Leptospirosis" },
  { value: "Lyme", label: "Lyme Disease" },
  { value: "Influenza", label: "Influenza" },
  { value: "FVRCP", label: "FVRCP" },
  { value: "Feline Leukemia", label: "Feline Leukemia" },
];

const ANTIPARASITIC_OPTIONS = [
  { value: "Internal Deworming", label: "Internal Deworming" },
  { value: "External Parasites", label: "External Parasites" },
  { value: "Heartworm Prevention", label: "Heartworm Prevention" },
  { value: "Flea Treatment", label: "Flea Treatment" },
  { value: "Tick Treatment", label: "Tick Treatment" },
];

const REMINDER_OPTIONS = [
  { value: "none", label: "No reminder" },
  { value: "custom", label: "Custom date" },
  { value: "21", label: "After 21 days" },
  { value: "30", label: "After 1 month" },
  { value: "90", label: "After 3 months" },
  { value: "180", label: "After 6 months" },
  { value: "365", label: "After 12 months" },
];

const EditVisit = ({ visit, visitId, onClose, authToken }) => {
  // Add state for visit details
  const [visitDetails, setVisitDetails] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true); // Rename existing loading state
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("general");
  const [visitDate, setVisitDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [weight, setWeight] = useState("");
  const [unitMeasure, setUnitMeasure] = useState("");
  const [unitMeasures, setUnitMeasures] = useState([]);
  const [vaccineReminders, setVaccineReminders] = useState({});
  const [antiparasiticReminders, setAntiparasiticReminders] = useState({});
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [scale, setScale] = useState(1);
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [notes, setNotes] = useState("");
  const { user, selectedUser } = useContext(UserContext);
  const users = user?.users;
  const [selectedVetUser, setSelectedVetUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const notesTextAreaRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add this new state
  const [showPetProfile, setShowPetProfile] = useState(false);

  // Set default user if none selected
  useEffect(() => {
    if (users && users.length > 0 && !selectedVetUser) {
      const defaultVetUser = users.find(
        (user) => user.ID === visitDetails?.visit?.VetUserID
      );
      setSelectedVetUser(defaultVetUser || selectedUser);
    }
  }, [users, selectedVetUser, selectedUser, visitDetails?.visit?.VetUserID]);

  // Fetch visit details when visitId changes
  useEffect(() => {
    const fetchVisitDetails = async () => {
      setIsLoadingDetails(true); // Show loading when starting to fetch
      try {
        const details = await getVetVisitDetails(visitId, authToken);
        setVisitDetails(details);
        setNotes(details.visit.Notes || "");
        setVisitDate(details.visit.VisitDate.split("T")[0]);

        if (details.petWellnessRecords?.weight?.records?.[0]) {
          const weightRecord = details.petWellnessRecords.weight.records[0];
          setWeight(weightRecord.WellnessPropertyValue || "");
          setUnitMeasure(weightRecord.GenUnitOfMeasureId?.toString() || "");
        }

        if (details.petWellnessRecords?.vaccinations?.records) {
          const initialVaccineReminders = {};
          details.petWellnessRecords.vaccinations.records.forEach((vaccine) => {
            initialVaccineReminders[vaccine.ID] = {
              option: vaccine.NextReminderDate ? "custom" : "none",
              date: vaccine.NextReminderDate?.split("T")[0] || null,
            };
          });
          setVaccineReminders(initialVaccineReminders);
        }

        if (details.petWellnessRecords?.antiparasitics?.records) {
          const initialAntiparasiticReminders = {};
          details.petWellnessRecords.antiparasitics.records.forEach(
            (antiparasitic) => {
              initialAntiparasiticReminders[antiparasitic.ID] = {
                option: antiparasitic.NextReminderDate ? "custom" : "none",
                date: antiparasitic.NextReminderDate?.split("T")[0] || null,
              };
            }
          );
          setAntiparasiticReminders(initialAntiparasiticReminders);
        }

        if (details.petWellnessRecords?.weight?.unitOfMeasures) {
          setUnitMeasures(details.petWellnessRecords.weight.unitOfMeasures);
          if (
            !details.petWellnessRecords?.weight?.records?.[0]
              ?.GenUnitOfMeasureId
          ) {
            setUnitMeasure(
              details.petWellnessRecords.weight.unitOfMeasures[0].id.toString()
            );
          }
        }

        if (users && details.visit.VetUserID) {
          const defaultVetUser = users.find(
            (user) => user.ID === details.visit.VetUserID
          );
          if (defaultVetUser) {
            setSelectedVetUser(defaultVetUser);
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoadingDetails(false); // Hide loading when done
      }
    };

    if (visitId && authToken) {
      fetchVisitDetails();
    }
  }, [visitId, authToken, users]);

  const calculateFutureDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + parseInt(days));
    return date.toISOString().split("T")[0];
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const newId = Date.now();
      const imageUrl = URL.createObjectURL(file);

      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          pictures: {
            ...prev.petWellnessRecords.pictures,
            records: [
              {
                ID: newId,
                PetProfileId: visitDetails.petProfile[0].ID,
                CreatedDate: new Date().toISOString(),
                ActionDate: new Date().toISOString(),
                VetVisitID: visitId,
                NextReminderDate: null,
                WellnessPropertyValue: null,
                PetWellnessCategoryTypeId: 18,
                GenUnitOfMeasureId: null,
                VetName: "",
                ImagePath: imageUrl,
                ImageHostLink: "",
                Notes: "",
                isDeleted: 0,
                file: file,
                isNew: true,
              },
              ...prev.petWellnessRecords.pictures.records,
            ],
          },
        },
      }));
    }
    setShowUploadOptions(false);
  };

  const handleCameraCapture = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const newId = Date.now();
      const imageUrl = URL.createObjectURL(file);

      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          pictures: {
            ...prev.petWellnessRecords.pictures,
            records: [
              {
                ID: newId,
                PetProfileId: visitDetails.petProfile[0].ID,
                CreatedDate: new Date().toISOString(),
                ActionDate: new Date().toISOString(),
                VetVisitID: visitId,
                NextReminderDate: null,
                WellnessPropertyValue: null,
                PetWellnessCategoryTypeId: 18,
                GenUnitOfMeasureId: null,
                VetName: "",
                ImagePath: imageUrl,
                ImageHostLink: "",
                Notes: "",
                isDeleted: 0,
                file: file,
                isNew: true,
              },
              ...prev.petWellnessRecords.pictures.records,
            ],
          },
        },
      }));
    }
    setShowUploadOptions(false);
  };

  // Add useEffect for click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowUploadOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const NotesComponent = () => (
    <NotesSection>
      <NotesLabel>Notes:</NotesLabel>
      <NotesTextArea
        ref={notesTextAreaRef}
        placeholder="Add notes..."
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
          // Ensure focus is maintained after state update
          if (notesTextAreaRef.current) {
            const cursorPosition = notesTextAreaRef.current.selectionStart;
            setTimeout(() => {
              notesTextAreaRef.current.focus();
              notesTextAreaRef.current.setSelectionRange(
                cursorPosition,
                cursorPosition
              );
            }, 0);
          }
        }}
      />
    </NotesSection>
  );

  const renderTabContent = () => {
    const handleDeleteVaccine = (id) => {
      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          vaccinations: {
            ...prev.petWellnessRecords.vaccinations,
            records: prev.petWellnessRecords.vaccinations.records.filter(
              (record) => record.ID !== id
            ),
          },
        },
      }));
      const newVaccineReminders = { ...vaccineReminders };
      delete newVaccineReminders[id];
      setVaccineReminders(newVaccineReminders);
    };

    const handleDeleteAntiparasitic = (id) => {
      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          antiparasitics: {
            ...prev.petWellnessRecords.antiparasitics,
            records: prev.petWellnessRecords.antiparasitics.records.filter(
              (record) => record.ID !== id
            ),
          },
        },
      }));
      const newAntiparasiticReminders = { ...antiparasiticReminders };
      delete newAntiparasiticReminders[id];
      setAntiparasiticReminders(newAntiparasiticReminders);
    };

    const handleAddVaccine = () => {
      const newId = Date.now();
      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          vaccinations: {
            ...prev.petWellnessRecords.vaccinations,
            records: [
              {
                ID: newId,
                PetProfileId: visitDetails.petProfile[0].ID,
                CreatedDate: new Date().toISOString(),
                ActionDate: new Date().toISOString(),
                VetVisitID: visitId,
                NextReminderDate: null,
                WellnessPropertyValue: "",
                PetWellnessCategoryTypeId: 9,
                GenUnitOfMeasureId: null,
                VetName: "",
                ImagePath: "",
                ImageHostLink: "",
                Notes: "",
                isDeleted: 0,
              },
              ...prev.petWellnessRecords.vaccinations.records,
            ],
          },
        },
      }));
      setVaccineReminders((prev) => ({
        ...prev,
        [newId]: {
          option: "none",
          date: null,
        },
      }));
    };

    const handleAddAntiparasitic = () => {
      const newId = Date.now();
      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          antiparasitics: {
            ...prev.petWellnessRecords.antiparasitics,
            records: [
              {
                ID: newId,
                PetProfileId: visitDetails.petProfile[0].ID,
                CreatedDate: new Date().toISOString(),
                ActionDate: new Date().toISOString(),
                VetVisitID: visitId,
                NextReminderDate: null,
                WellnessPropertyValue: "",
                PetWellnessCategoryTypeId: 10,
                GenUnitOfMeasureId: null,
                VetName: "",
                ImagePath: "",
                ImageHostLink: "",
                Notes: "",
                isDeleted: 0,
              },
              ...prev.petWellnessRecords.antiparasitics.records,
            ],
          },
        },
      }));
      setAntiparasiticReminders((prev) => ({
        ...prev,
        [newId]: {
          option: "none",
          date: null,
        },
      }));
    };

    const handleDeleteConsultation = (id) => {
      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          consultations: {
            ...prev.petWellnessRecords.consultations,
            records: prev.petWellnessRecords.consultations.records.filter(
              (record) => record.ID !== id
            ),
          },
        },
      }));
    };

    const handleAddConsultation = () => {
      const newId = Date.now();
      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          consultations: {
            ...prev.petWellnessRecords.consultations,
            records: [
              {
                ID: newId,
                PetProfileId: visitDetails.petProfile[0].ID,
                CreatedDate: new Date().toISOString(),
                ActionDate: new Date().toISOString(),
                VetVisitID: visitId,
                NextReminderDate: null,
                WellnessPropertyValue: "",
                PetWellnessCategoryTypeId: 17,
                GenUnitOfMeasureId: null,
                VetName: "",
                Notes: "",
                isDeleted: 0,
              },
              ...prev.petWellnessRecords.consultations.records,
            ],
          },
        },
      }));
    };

    const handleDeletePicture = (id) => {
      setVisitDetails((prev) => ({
        ...prev,
        petWellnessRecords: {
          ...prev.petWellnessRecords,
          pictures: {
            ...prev.petWellnessRecords.pictures,
            records: prev.petWellnessRecords.pictures.records.map((record) =>
              record.ID === id
                ? { ...record, isDeleted: 1 } // Mark as deleted instead of removing
                : record
            ),
          },
        },
      }));
    };

    const handleImageClick = (imageUrl) => {
      setSelectedImage(imageUrl);
      setScale(1); // Reset scale when opening new image
    };

    const handleWheel = (e) => {
      e.preventDefault();
      const newScale = scale + (e.deltaY > 0 ? -0.1 : 0.1);
      setScale(Math.min(Math.max(0.5, newScale), 3)); // Limit scale between 0.5 and 3
    };

    const handleModalClick = (e) => {
      if (e.target === e.currentTarget) {
        setSelectedImage(null);
        setScale(1);
      }
    };

    switch (activeTab) {
      case "general":
        return (
          <GeneralContainer>
            <WeightContainer>
              <WeightLabel>Weight:</WeightLabel>
              <WeightInput
                type="number"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                placeholder="Enter weight"
              />
              <UnitSelect
                value={unitMeasure}
                onChange={(e) => setUnitMeasure(e.target.value)}
              >
                <option value="">Select unit</option>
                {unitMeasures.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.title}
                  </option>
                ))}
              </UnitSelect>
            </WeightContainer>

            <SectionHeader>
              <SectionTitle>Vaccinations</SectionTitle>
              <AddButton onClick={handleAddVaccine}>
                <span>+</span>
              </AddButton>
            </SectionHeader>
            <RecordList>
              {visitDetails?.petWellnessRecords?.vaccinations?.records.map(
                (vaccine) => (
                  <RecordItem key={vaccine.ID}>
                    <RecordInfo>
                      <RecordHeader>
                        <SelectDropdown
                          value={vaccine.WellnessPropertyValue || ""}
                          onChange={(e) => {
                            setVisitDetails((prev) => ({
                              ...prev,
                              petWellnessRecords: {
                                ...prev.petWellnessRecords,
                                vaccinations: {
                                  ...prev.petWellnessRecords.vaccinations,
                                  records:
                                    prev.petWellnessRecords.vaccinations.records.map(
                                      (record) =>
                                        record.ID === vaccine.ID
                                          ? {
                                              ...record,
                                              WellnessPropertyValue:
                                                e.target.value,
                                            }
                                          : record
                                    ),
                                },
                              },
                            }));
                          }}
                          style={{ width: "200px" }}
                        >
                          <option value="">Select Vaccination</option>
                          {VACCINE_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </SelectDropdown>
                        <DeleteButton
                          onClick={() => handleDeleteVaccine(vaccine.ID)}
                        >
                          ×
                        </DeleteButton>
                      </RecordHeader>
                      <ReminderOptionsContainer>
                        {REMINDER_OPTIONS.map((option) => (
                          <ReminderOption key={option.value}>
                            <input
                              type="radio"
                              name={`vaccine-reminder-${vaccine.ID}`}
                              value={option.value}
                              checked={
                                vaccineReminders[vaccine.ID]?.option ===
                                option.value
                              }
                              onChange={(e) => {
                                const selectedOption = e.target.value;
                                const newDate =
                                  selectedOption === "custom"
                                    ? vaccine.NextReminderDate?.split("T")[0]
                                    : selectedOption === "none"
                                    ? null
                                    : calculateFutureDate(selectedOption);
                                setVaccineReminders((prev) => ({
                                  ...prev,
                                  [vaccine.ID]: {
                                    option: selectedOption,
                                    date: newDate,
                                  },
                                }));
                              }}
                            />
                            {option.label}
                            {option.value !== "custom" &&
                              option.value !== "none" &&
                              vaccineReminders[vaccine.ID]?.option ===
                                option.value && (
                                <ReminderDateLabel isReminderDate={true}>
                                  (Next reminder:{" "}
                                  {new Date(
                                    vaccineReminders[vaccine.ID]?.date
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                                  )
                                </ReminderDateLabel>
                              )}
                          </ReminderOption>
                        ))}
                      </ReminderOptionsContainer>
                      {vaccineReminders[vaccine.ID]?.option === "custom" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <ReminderDateLabel>Next reminder:</ReminderDateLabel>
                          <DateInput
                            type="date"
                            value={
                              vaccineReminders[vaccine.ID]?.date ||
                              vaccine.NextReminderDate?.split("T")[0] ||
                              ""
                            }
                            onChange={(e) => {
                              setVaccineReminders((prev) => ({
                                ...prev,
                                [vaccine.ID]: {
                                  option: "custom",
                                  date: e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                      )}
                    </RecordInfo>
                  </RecordItem>
                )
              )}
            </RecordList>

            <SectionHeader>
              <SectionTitle>Antiparasitics</SectionTitle>
              <AddButton onClick={handleAddAntiparasitic}>
                <span>+</span>
              </AddButton>
            </SectionHeader>
            <RecordList>
              {visitDetails?.petWellnessRecords?.antiparasitics?.records.map(
                (antiparasitic) => (
                  <RecordItem key={antiparasitic.ID}>
                    <RecordInfo>
                      <RecordHeader>
                        <SelectDropdown
                          value={antiparasitic.WellnessPropertyValue || ""}
                          onChange={(e) => {
                            setVisitDetails((prev) => ({
                              ...prev,
                              petWellnessRecords: {
                                ...prev.petWellnessRecords,
                                antiparasitics: {
                                  ...prev.petWellnessRecords.antiparasitics,
                                  records:
                                    prev.petWellnessRecords.antiparasitics.records.map(
                                      (record) =>
                                        record.ID === antiparasitic.ID
                                          ? {
                                              ...record,
                                              WellnessPropertyValue:
                                                e.target.value,
                                            }
                                          : record
                                    ),
                                },
                              },
                            }));
                          }}
                          style={{ width: "200px" }}
                        >
                          <option value="">Select Antiparasitic</option>
                          {ANTIPARASITIC_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </SelectDropdown>
                        <DeleteButton
                          onClick={() =>
                            handleDeleteAntiparasitic(antiparasitic.ID)
                          }
                        >
                          ×
                        </DeleteButton>
                      </RecordHeader>
                      <ReminderOptionsContainer>
                        {REMINDER_OPTIONS.map((option) => (
                          <ReminderOption key={option.value}>
                            <input
                              type="radio"
                              name={`antiparasitic-reminder-${antiparasitic.ID}`}
                              value={option.value}
                              checked={
                                antiparasiticReminders[antiparasitic.ID]
                                  ?.option === option.value
                              }
                              onChange={(e) => {
                                const selectedOption = e.target.value;
                                const newDate =
                                  selectedOption === "custom"
                                    ? antiparasitic.NextReminderDate?.split(
                                        "T"
                                      )[0]
                                    : selectedOption === "none"
                                    ? null
                                    : calculateFutureDate(selectedOption);
                                setAntiparasiticReminders((prev) => ({
                                  ...prev,
                                  [antiparasitic.ID]: {
                                    option: selectedOption,
                                    date: newDate,
                                  },
                                }));
                              }}
                            />
                            {option.label}
                            {option.value !== "custom" &&
                              option.value !== "none" &&
                              antiparasiticReminders[antiparasitic.ID]
                                ?.option === option.value && (
                                <ReminderDateLabel isReminderDate={true}>
                                  (Next reminder:{" "}
                                  {new Date(
                                    antiparasiticReminders[
                                      antiparasitic.ID
                                    ]?.date
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                                  )
                                </ReminderDateLabel>
                              )}
                          </ReminderOption>
                        ))}
                      </ReminderOptionsContainer>
                      {antiparasiticReminders[antiparasitic.ID]?.option ===
                        "custom" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <ReminderDateLabel>Next reminder:</ReminderDateLabel>
                          <DateInput
                            type="date"
                            value={
                              antiparasiticReminders[antiparasitic.ID]?.date ||
                              antiparasitic.NextReminderDate?.split("T")[0] ||
                              ""
                            }
                            onChange={(e) => {
                              setAntiparasiticReminders((prev) => ({
                                ...prev,
                                [antiparasitic.ID]: {
                                  option: "custom",
                                  date: e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                      )}
                    </RecordInfo>
                  </RecordItem>
                )
              )}
            </RecordList>

            <NotesComponent />
          </GeneralContainer>
        );
      case "consultations":
        return (
          <GeneralContainer>
            <SectionHeader>
              <SectionTitle>Consultations</SectionTitle>
              <AddButton onClick={handleAddConsultation}>
                <span>+</span>
              </AddButton>
            </SectionHeader>
            <RecordList>
              {visitDetails?.petWellnessRecords?.consultations?.records.map(
                (consultation) => (
                  <RecordItem key={consultation.ID}>
                    <RecordInfo>
                      <RecordHeader>
                        <input
                          type="text"
                          placeholder="Enter consultation reason"
                          value={consultation.WellnessPropertyValue || ""}
                          onChange={(e) => {
                            setVisitDetails((prev) => ({
                              ...prev,
                              petWellnessRecords: {
                                ...prev.petWellnessRecords,
                                consultations: {
                                  ...prev.petWellnessRecords.consultations,
                                  records:
                                    prev.petWellnessRecords.consultations.records.map(
                                      (record) =>
                                        record.ID === consultation.ID
                                          ? {
                                              ...record,
                                              WellnessPropertyValue:
                                                e.target.value,
                                            }
                                          : record
                                    ),
                                },
                              },
                            }));
                          }}
                          style={{
                            padding: "6px",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                            fontSize: "13px",
                            width: "200px",
                          }}
                        />
                        <DeleteButton
                          onClick={() =>
                            handleDeleteConsultation(consultation.ID)
                          }
                        >
                          ×
                        </DeleteButton>
                      </RecordHeader>
                      <div
                        style={{
                          marginTop: "8px",
                          width: "100%",
                          boxSizing: "border-box",
                          paddingRight: "8px",
                        }}
                      >
                        <textarea
                          placeholder="Add notes..."
                          value={consultation.Notes || ""}
                          onChange={(e) => {
                            setVisitDetails((prev) => ({
                              ...prev,
                              petWellnessRecords: {
                                ...prev.petWellnessRecords,
                                consultations: {
                                  ...prev.petWellnessRecords.consultations,
                                  records:
                                    prev.petWellnessRecords.consultations.records.map(
                                      (record) =>
                                        record.ID === consultation.ID
                                          ? { ...record, Notes: e.target.value }
                                          : record
                                    ),
                                },
                              },
                            }));
                          }}
                          style={{
                            width: "100%",
                            minHeight: "60px",
                            padding: "8px",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                            fontSize: "13px",
                            resize: "vertical",
                            boxSizing: "border-box",
                          }}
                        />
                      </div>
                    </RecordInfo>
                  </RecordItem>
                )
              )}
            </RecordList>

            <NotesComponent />
          </GeneralContainer>
        );
      case "pictures":
        return (
          <GeneralContainer>
            <SectionHeader style={{ position: "relative" }}>
              <SectionTitle>Pictures</SectionTitle>
              <div ref={dropdownRef}>
                <AddButton
                  onClick={() => setShowUploadOptions(!showUploadOptions)}
                >
                  <span>+</span>
                </AddButton>
                {showUploadOptions && (
                  <ImageUploadOptions>
                    <UploadOption onClick={() => fileInputRef.current?.click()}>
                      <FiUpload size={16} />
                      Upload Picture
                    </UploadOption>
                    <UploadOption
                      onClick={() => cameraInputRef.current?.click()}
                    >
                      <FiCamera size={16} />
                      Take Picture
                    </UploadOption>
                  </ImageUploadOptions>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileUpload}
                />
                <input
                  type="file"
                  ref={cameraInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  capture="environment"
                  onChange={handleCameraCapture}
                />
              </div>
            </SectionHeader>
            <ImageGrid>
              {visitDetails?.petWellnessRecords?.pictures?.records
                .filter((picture) => !picture.isDeleted) // Only show non-deleted pictures
                .map((picture) => (
                  <ImageContainer
                    key={picture.ID}
                    onClick={() =>
                      handleImageClick(
                        `${picture.ImageHostLink}${picture.ImagePath}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <StyledImage
                      src={`${picture.ImageHostLink}${picture.ImagePath}`}
                      alt="Visit picture"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "placeholder-image-url";
                      }}
                    />
                    <ImageDeleteButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent modal from opening when deleting
                        handleDeletePicture(picture.ID);
                      }}
                    >
                      ×
                    </ImageDeleteButton>
                  </ImageContainer>
                ))}
            </ImageGrid>

            {selectedImage && (
              <ImageModal onClick={handleModalClick}>
                <CloseModalButton
                  onClick={() => {
                    setSelectedImage(null);
                    setScale(1);
                  }}
                >
                  ×
                </CloseModalButton>
                <ZoomableImage
                  src={selectedImage}
                  alt="Enlarged visit picture"
                  scale={scale}
                  onWheel={handleWheel}
                  onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking image
                />
              </ImageModal>
            )}

            <NotesComponent />
          </GeneralContainer>
        );
      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      // Helper function for image conversion (unchanged)
      const fetchImageAsFile = async (imageUrl, imageName) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return new File([blob], imageName, { type: blob.type });
      };

      // Format pictures data (unchanged)
      const pictures = await Promise.all(
        visitDetails.petWellnessRecords?.pictures?.records
          .filter((pic) => !pic.isDeleted)
          .map(async (pic) => {
            let file = pic.file;
            if (!file) {
              const imageUrl = `${pic.ImageHostLink}${pic.ImagePath}`;
              file = await fetchImageAsFile(imageUrl, `image_${pic.ID}.jpg`);
            }
            return {
              id: pic.ID,
              file: file,
              imagePath: pic.ImagePath,
              imageHostLink: pic.ImageHostLink,
              notes: pic.Notes || "",
            };
          })
      );

      // Create the formatted JSON object with conditional weight inclusion
      const formattedVisit = {
        petId: visitDetails.petProfile[0].ID,
        vetUserId: selectedVetUser?.ID || null,
        vetVisitId: visitId,
        visitDate: visitDate,
        notes: notes,
        // Only include weight if both weight and unitMeasure are present and weight is not empty
        ...(weight && weight.trim() !== "" && unitMeasure
          ? {
              weight: [
                {
                  recordValue: parseFloat(weight),
                  date: visitDate,
                  unitOfMeasureId: parseInt(unitMeasure),
                  unitOfMeasure:
                    unitMeasures.find((u) => u.id === parseInt(unitMeasure))
                      ?.title || "",
                },
              ],
            }
          : { weight: [] }),
        vaccinations: visitDetails.petWellnessRecords?.vaccinations?.records
          .filter((v) => v.WellnessPropertyValue && !v.isDeleted)
          .map((v) => ({
            title: v.WellnessPropertyValue,
            date: visitDate,
            nextReminderDate: vaccineReminders[v.ID]?.date || null,
          })),
        antiparasitics: visitDetails.petWellnessRecords?.antiparasitics?.records
          .filter((a) => a.WellnessPropertyValue && !a.isDeleted)
          .map((a) => ({
            title: a.WellnessPropertyValue,
            date: visitDate,
            nextReminderDate: antiparasiticReminders[a.ID]?.date || null,
          })),
        consultations: visitDetails.petWellnessRecords?.consultations?.records
          .filter((c) => c.WellnessPropertyValue && !c.isDeleted)
          .map((c) => ({
            title: c.WellnessPropertyValue,
            notes: c.Notes || "",
            date: visitDate,
            nextReminderDate: null,
          })),
      };

      // Call the saveVetVisit connector
      const response = await saveVetVisit(formattedVisit, pictures, authToken);

      if (response) {
        console.log("Visit saved successfully");
        onClose(); // Close the modal after successful save
      }
    } catch (error) {
      console.error("Error saving visit:", error);
      setError(
        "An error occurred while saving the visit. Please try again.",
        error.message
      );
      // You might want to show an error message to the user here
    } finally {
      setIsSubmitting(false); // Set loading state to false when done
    }
  };

  // Add loading and error states to the return
  return (
    <Overlay>
      <Content>
        <Header>
          <h2>Edit Vet Visit</h2>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>

        {isLoadingDetails || isSubmitting ? (
          <ContentArea>
            <LoadingImage src={loadingCat} alt="Loading..." />
          </ContentArea>
        ) : error ? (
          <ContentArea>
            <ErrorMessage>{error}</ErrorMessage>
          </ContentArea>
        ) : (
          <>
            {visitDetails && (
              <>
                <PetProfile
                  onClick={() => setShowPetProfile(true)}
                  style={{ cursor: "pointer" }}
                >
                  {visitDetails.petProfile[0].petImage ===
                  process.env.REACT_APP_BLOB_STORAGE_URL ? (
                    <FiImage size={100} color="#999" />
                  ) : (
                    <PetImage
                      src={visitDetails.petProfile[0].petImage}
                      alt={visitDetails.petProfile[0].petName}
                    />
                  )}
                  <PetInfo>
                    <h3>
                      {visitDetails.petProfile[0].petName}
                      <PetAge>{visitDetails.petProfile[0].age} years</PetAge>
                    </h3>
                    <PetType>
                      {visitDetails.petProfile[0].petTypeImage && (
                        <PetTypeIcon
                          src={visitDetails.petProfile[0].petTypeImage}
                          alt={visitDetails.petProfile[0].petType}
                        />
                      )}
                      {visitDetails.petProfile[0].petType}
                    </PetType>
                    <p>Breed: {visitDetails.petProfile[0].petBreed}</p>
                    <p>Gender: {visitDetails.petProfile[0].petGender}</p>
                    <p>Owner: {visitDetails.petProfile[0].ownerNameForVet}</p>
                    <p>
                      Contact: {visitDetails.petProfile[0].ownerPhoneForVet}
                    </p>
                  </PetInfo>
                </PetProfile>

                {showPetProfile && visitDetails.petProfile[0] && (
                  <VetPetProfile
                    pet={visitDetails.petProfile[0]}
                    onClose={() => setShowPetProfile(false)}
                    authToken={authToken}
                  />
                )}

                <TabContainer>
                  <Tab
                    isActive={activeTab === "general"}
                    onClick={() => setActiveTab("general")}
                  >
                    General
                  </Tab>
                  <Divider>|</Divider>
                  <Tab
                    isActive={activeTab === "consultations"}
                    onClick={() => setActiveTab("consultations")}
                  >
                    Consultations
                  </Tab>
                  <Divider>|</Divider>
                  <Tab
                    isActive={activeTab === "pictures"}
                    onClick={() => setActiveTab("pictures")}
                  >
                    Pictures
                  </Tab>
                </TabContainer>

                <ScrollableContent>{renderTabContent()}</ScrollableContent>

                <BottomControls>
                  <LeftControls>
                    <DateContainer>
                      <label>Visit Date:</label>
                      <input
                        type="date"
                        value={visitDate}
                        onChange={(e) => setVisitDate(e.target.value)}
                      />
                    </DateContainer>
                    <DropdownContainer ref={dropdownRef}>
                      <ButtonStyled
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        style={{
                          backgroundColor: isDropdownOpen ? "#5951fc" : "white",
                          color: isDropdownOpen ? "white" : "grey",
                        }}
                        isDropdown
                      >
                        <FaUser style={{ marginRight: "5px" }} />{" "}
                        {selectedVetUser?.Name || "Select User"}
                      </ButtonStyled>
                      {isDropdownOpen && (
                        <DropdownContent>
                          {users?.map((user) => (
                            <DropdownItem
                              key={user.id}
                              onClick={() => {
                                setSelectedVetUser(user);
                                setIsDropdownOpen(false);
                              }}
                            >
                              {user.Name}
                            </DropdownItem>
                          ))}
                        </DropdownContent>
                      )}
                    </DropdownContainer>
                  </LeftControls>
                  <ButtonGroup>
                    <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                  </ButtonGroup>
                </BottomControls>
              </>
            )}
          </>
        )}
      </Content>
    </Overlay>
  );
};

// Add styled component for error message
const ErrorMessage = styled.div`
  color: #ff4d4f;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  padding: 12px;
  border-radius: 4px;
  margin: 10px 0;
  text-align: center;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px 20px 5px 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  height: 93%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;

  h2 {
    flex-grow: 1;
    text-align: center;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.2rem;
    }
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  color: #5951fc;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 3px;
  }
`;

const PetType = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  margin: 2px 0;
`;

const PetTypeIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  filter: invert(37%) sepia(14%) saturate(6889%) hue-rotate(232deg)
    brightness(99%) contrast(98%);
  opacity: 0.5;
`;

const PetProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    padding: 12px;
    gap: 12px;
  }
`;

const PetImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const PetInfo = styled.div`
  flex: 1;

  h3 {
    margin: 0 0 10px 0;
  }

  p {
    margin: 5px 0;
    color: #666;
  }

  @media (max-width: 768px) {
    h3 {
      margin: 0 0 5px 0;
      font-size: 1rem;
    }

    p {
      margin: 3px 0;
      font-size: 0.85rem;
    }
  }
`;

const PetAge = styled.span`
  font-size: 0.8em;
  color: #666;
  margin-left: 8px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 20px 0;

  @media (max-width: 768px) {
    gap: 8px;
    margin: 12px 0;
  }
`;

const Tab = styled.button`
  background: ${(props) =>
    props.isActive ? "rgba(89, 81, 252, 0.1)" : "none"};
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#5951fc" : "#666")};
  font-size: 16px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: #5951fc;
    background: rgba(89, 81, 252, 0.05);
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`;

const Divider = styled.span`
  color: #666;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 5px;
  height: calc(100% - 430px);

  @media (max-width: 768px) {
    padding: 12px;
    height: calc(100% - 355px);
  }
`;

const BottomControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-top: 1px solid #eee;
  gap: 12px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  input {
    max-width: 120px;
    padding: 6px;
    font-size: 12px;
  }
`;

const LeftControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button`
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
`;

const SubmitButton = styled(Button)`
  background: #5951fc;
  border: 1px solid #5951fc;
  color: white;

  &:hover {
    background: #4842e3;
  }
`;

const GeneralContainer = styled.div`
  padding: 20px;
`;

const WeightContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

const WeightLabel = styled.label`
  min-width: 60px;
  color: #666;
`;

const WeightInput = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 120px;

  &:focus {
    border-color: #5951fc;
    outline: none;
  }
`;

const UnitSelect = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 80px;

  &:focus {
    border-color: #5951fc;
    outline: none;
  }
`;

const SectionTitle = styled.h3`
  color: #666;
  margin: 20px 0 10px;
  font-size: 16px;
`;

const RecordList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RecordItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const RecordInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const DateInput = styled.input`
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  width: 130px;
  height: 20px;
  margin: 0;

  &:focus {
    border-color: #5951fc;
    outline: none;
  }
`;

const SelectDropdown = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  background: white;

  &:focus {
    border-color: #5951fc;
    outline: none;
  }
`;

const ReminderOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
  padding: 8px;
  border-radius: 4px;
`;

const ReminderOption = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #666;
  cursor: pointer;
  white-space: nowrap;

  input[type="radio"] {
    cursor: pointer;
    accent-color: #5951fc;
    margin: 0;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

const AddButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #5951fc;
  background: white;
  color: #5951fc;
  font-size: 14px;
  line-height: 0;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s;
  margin-bottom: 2px;

  &:hover {
    background: #5951fc;
    color: white;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`;

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ff4d4f;
  background: white;
  color: #ff4d4f;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s;

  &:hover {
    background: #ff4d4f;
    color: white;
  }
`;

const RecordHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const ReminderDateLabel = styled.span`
  font-size: 12px;
  color: ${(props) => (props.isReminderDate ? "#5951fc" : "#666")};
  margin-left: 4px;
  display: flex;
  align-items: center;
  height: 28px;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  padding: 16px;
`;

const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ddd;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageDeleteButton = styled(DeleteButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.9);
  border: none;

  &:hover {
    background: rgba(255, 77, 79, 0.9);
    color: white;
  }
`;

const ImageModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ZoomableImage = styled.img`
  max-height: 90vh;
  max-width: 90vw;
  object-fit: contain;
  transform-origin: center;
  transform: scale(${(props) => props.scale});
  transition: transform 0.1s ease-out;
  cursor: zoom-in;
`;

const CloseModalButton = styled(CloseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 32px;

  &:hover {
    color: #ddd;
  }
`;

const ImageUploadOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
`;

const UploadOption = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: none;
  background: none;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: #5951fc;
  }
`;

const NotesSection = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
`;

const NotesLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-size: 14px;
`;

const NotesTextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;

  &:focus {
    border-color: #5951fc;
    outline: none;
  }

  &::placeholder {
    color: #999;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1;
  margin-bottom: 5px;
`;

const DropdownItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const ButtonStyled = styled(Button)`
  border: 1px solid grey;
  color: grey;
  border-radius: ${(props) => (props.isDropdown ? "5px" : "20px")};
  background-color: white;
  white-space: nowrap;
  display: flex;
  align-items: center;

  // Responsive sizing
  @media (min-width: 769px) {
    padding: 6px 12px; // Reduced from 10px 20px
    font-size: 12px; // Reduced from 16px
  }

  @media (max-width: 768px) {
    padding: 4px 8px; // Reduced from 6px 12px
    font-size: 11px; // Reduced from 12px

    // Make icons smaller
    svg {
      width: 10px; // Reduced from 12px
      height: 10px; // Reduced from 12px
      margin-right: 3px !important; // Reduced from 4px
    }
  }

  &:hover {
    background-color: #5951fc;
    color: white;
  }
`;

const ContentArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const LoadingImage = styled.img`
  width: 150px;
  height: auto;
`;

export default EditVisit;
