import { postRequest, getRequest, postFormDataRequest } from '../utils/request';

// VetLogin endpoint
export const vetLogin = async (username, password) => {
  try {
    const body = {
        username: username,
        password: password,
      };
    const response = await postRequest('/api/VetAccounts/Login', body);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to reset password:', error);
    throw error;
  }
};

// GetVetVisits endpoint
export const getVetVisits = async (isFinished, offset, limit, token) => {
    const response = await getRequest(`/api/VetPets/GetVetVisits?isFinished=${isFinished}&offset=${offset}&limit=${limit}`, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};

// DeleteVetVisit endpoint
export const deleteVetVisit = async (visitId, petId, token) => {
  try {
    const body = {
        vetVisitId: visitId,
        petId: petId,
      };
    const response = await postRequest('/api/VetPets/DeleteVetVisit', body, token);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to delete visit:', error);
    throw error;
  }
};

// GetVetPets endpoint
export const getVetPets = async (offset, limit, searchTerm, token) => {
    const response = await getRequest(`/api/VetPets/GetVetPets?offset=${offset}&limit=${limit}&searchTerm=${searchTerm}`, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};

// CreateVetVisit endpoint
export const createVetVisit = async (petId, visitDate, vetUserId, token) => {
  try {
    const body = {
        petId: petId,
        visitDate: visitDate,
        vetUserId: vetUserId,
      };
    const response = await postRequest('/api/VetPets/CreateVetVisit', body, token);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to delete visit:', error);
    throw error;
  }
};

// GetVetVisitDetails endpoint
export const getVetVisitDetails = async (visitId, token) => {
    const response = await getRequest(`/api/VetPets/GetVetVisitDetails?vetVisitId=${visitId}`, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};

// SaveVetVisit endpoint
export const saveVetVisit = async (visitJson, pictures, token) => {
  try {

    const response = await postFormDataRequest('/api/VetPets/SaveVetVisit', visitJson, pictures, token);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to save visit:', error);
    throw error;
  }
};

// GetVetReminders endpoint
export const getVetReminders = async(date, offset, limit, token) => {

  const body = {
    date: date,
    offset: offset,
    limit: limit,
  };

  const response = await postRequest(`/api/VetPets/GetVetReminders`, body, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};

// DeleteVetPet endpoint
export const deleteVetPet = async(petId, token) => {

  const body = {
    petId: petId,
  };

  const response = await postRequest(`/api/VetPets/DeleteVetPet`, body, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};

// CreateVetPet endpoint
export const createVetPet = async (petJson, pictures, token) => {
  try {
    const response = await postFormDataRequest('/api/VetPets/CreateVetPet', petJson, pictures, token);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to create pet:', error);
    throw error;
  }
};

// GetPetBreeds endpoint
export const getPetBreeds = async (petTypeId, token) => {
    const response = await getRequest(`/api/General/GetPetBreeds?PetTypeId=${petTypeId}`, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};

// GetVetPetDetails endpoint
export const getVetPetDetails = async (petProfileId, token) => {
    const response = await getRequest(`/api/VetPets/GetVetPetDetails?petProfileId=${petProfileId}`, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};

// UpdateVetPet endpoint
export const updateVetPet = async (petJson, pictures, token) => {
  try {

    const response = await postFormDataRequest('/api/VetPets/UpdateVetPet', petJson, pictures, token);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to update pet:', error);
    throw error;
  }
};

// GetPetWellnessCategories endpoint
export const getPetWellnessCategories = async (token) => {
    const response = await getRequest(`/api/PetWellness/GetPetWellnessCategories`, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};

// GetPetWellnessCategoryTypes endpoint
export const getPetWellnessCategoryTypes = async (petProfileId, categoryId, token) => {
    const response = await getRequest(`/api/PetWellness/GetPetWellnessCategoryTypes?petProfile=${petProfileId}&publicId=&categoryId=${categoryId}`, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};

// GetVetPetVisits endpoint
export const getVetPetVisits = async (petProfileId, offset, limit, token) => {
    const response = await getRequest(`/api/VetPets/GetVetPetVisits?petProfileId=${petProfileId}&offset=${offset}&limit=${limit}`, token);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }

    return response.data;
};