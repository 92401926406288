import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import loading_cat from "../../assets/loading_cat.gif"; // Adjust the path as needed
import {
  FaBirthdayCake,
  FaWeight,
  FaTransgender,
  FaPalette,
  FaMicrochip,
  FaIdCard,
  FaSyringe,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaInfoCircle,
  FaStar,
  FaRuler,
  FaHeartbeat,
  FaShower,
} from "react-icons/fa";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  getPetDetailsByPublicId,
  getPetWellnessCategories,
  getPetWellnessCategoryTypes,
} from "../../connectors/Pet"; // Adjust the import path as needed
import { GiSkills } from "react-icons/gi";

const PetProfile = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the navigate hook
  const searchParams = new URLSearchParams(location.search);
  const publicId = searchParams.get("publicId");
  const petProfileId = searchParams.get("petProfileId");
  const isFrom = searchParams.get("isFrom");
  const [petData, setPetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [wellnessCategories, setWellnessCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [activeTab, setActiveTab] = useState("About");
  const [activeSubcategoryTab, setActiveSubcategoryTab] = useState("");
  const [showLostPetInfo, setShowLostPetInfo] = useState(false); // State to toggle display of lost pet info
  const [userLocation, setUserLocation] = useState(null); // State to store user location
  const [locationRequested, setLocationRequested] = useState(false);

  useEffect(() => {
    const requestLocation = () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
          setUserLocation({
            latitude: 0,
            longitude: 0,
          });
        }
      );
    };

    const askForLocation = () => {
      if (
        window.confirm(
          "Help the owner of this pet find their lost pet by allowing location access."
        )
      ) {
        requestLocation();
      } else {
        setUserLocation({
          latitude: 0,
          longitude: 0,
        });
      }
    };

    if (petData?.isLost && !locationRequested && isFrom !== "Adoption") {
      askForLocation();
      setLocationRequested(true);
    } else if (!petData?.isLost && locationRequested == null) {
      askForLocation();
      setLocationRequested(true);
    }
  }, [petData, locationRequested, isFrom]);

  useEffect(() => {
    const fetchPetData = async () => {
      try {
        const latitude = userLocation?.latitude;
        const longitude = userLocation?.longitude;

        const petDataResponse = await getPetDetailsByPublicId(
          petProfileId,
          publicId,
          latitude,
          longitude
        );
        setPetData(petDataResponse);

        const wellnessDataResponse = await getPetWellnessCategories();
        setWellnessCategories(wellnessDataResponse);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPetData();
  }, [publicId, userLocation, petProfileId, isFrom]);

  if (loading) {
    return (
      <LoadingContainer>
        <img src={loading_cat} alt="Loading..." />
      </LoadingContainer>
    );
  }
  if (error) return <p>Error: {error}</p>;

  const handleLostPetInfoClick = () => {
    setShowLostPetInfo(!showLostPetInfo); // Toggle showLostPetInfo state
  };

  async function handleCategoryClick(category) {
    setActiveTab(category.title);

    try {
      const petCategoryInfo = await getPetWellnessCategoryTypes(
        petData.petId,
        publicId,
        category.id
      );
      setSubcategories(petCategoryInfo);
    } catch (err) {
      setError(err.message);
    }
  }

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setSubcategories([]); // Empty the subCategories array when a tab is clicked
  };

  const getCategoryIcon = (categoryId) => {
    switch (categoryId) {
      case 2:
        return <FaRuler />; // Measures icon
      case 4:
        return <FaHeartbeat />; // Health icon
      case 5:
        return <FaShower />; // Hygiene icon
      default:
        return <FaStar />; // Default icon for other categories
    }
  };

  return (
    <ProfileContainer>
      <Helmet>
        <title>{`${petData.petName}'s Profile - Pawers™`}</title>
        <meta
          name="description"
          content={`Explore the profile of ${petData.petName}, a ${petData.age}-year-old ${petData.petBreed}`}
        />
        <meta
          property="og:title"
          content={`${petData.petName}'s Profile - Pawers™`}
        />
        <meta
          property="og:description"
          content={`Explore the profile of ${petData.petName}, a ${petData.age}-year-old ${petData.petBreed}`}
        />
        <meta name="image" content={petData.petImage} />
        <meta property="og:image" content={petData.petImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header>
        <Link to="/">
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
          >
            &larr; Back
          </BackButton>
        </Link>
        {isFrom === "Adoption" && petData.isForAdoption === true && (
          <AdoptButton
            disabled={true}
            onClick={() => {
              /* Add adoption functionality here */
            }}
          >
            Adopt Me!
          </AdoptButton>
        )}
        <LogoContainer>
          <Logo
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            alt="Pawers Logo"
          />
        </LogoContainer>
      </Header>
      <Content>
        <PetDetails>
          <PetImage src={petData.petImage} />
          <PetInfo>
            <h1>{petData.petName}</h1>
            <p>{`${petData.age} years old`}</p>
            <BreedText>{petData.petBreed}</BreedText>{" "}
            {/* Apply the styled component here */}
          </PetInfo>
          {petData.isLost && isFrom !== "Adoption" && (
            <LostPetButton onClick={handleLostPetInfoClick}>
              Pet is Lost
            </LostPetButton>
          )}
        </PetDetails>
        {isFrom !== "Adoption" && petData.isLost && (
          <OrangeButton
            onClick={() => {
              setLocationRequested(null);
            }}
          >
            Help Find Pet
          </OrangeButton>
        )}
        {showLostPetInfo && petData.isLost && (
          <LostPetInfo>
            <LostPetHeader>
              <h2>Lost Pet Information</h2>
              <CloseButton onClick={handleLostPetInfoClick}>
                &times;
              </CloseButton>
            </LostPetHeader>

            <LostPetGrid>
              <LostPetDetail>
                <Label>Last Seen</Label>
                <Value>{petData.lostPetDetails.lostDate}</Value>
              </LostPetDetail>

              <LostPetDetail>
                <Label>Contact</Label>
                <Value>{petData.lostPetDetails.contactPhoneNumber}</Value>
              </LostPetDetail>

              <LostPetDetail>
                <Label>Location</Label>
                <Value>{petData.lostPetDetails.address}</Value>
              </LostPetDetail>

              <LostPetDetail>
                <Label>Reward</Label>
                <Value>
                  {petData.lostPetDetails.reward || "Not specified"}
                </Value>
              </LostPetDetail>
            </LostPetGrid>

            {petData.lostPetDetails.notes && (
              <Notes>
                <Label>Additional Notes</Label>
                <Value>{petData.lostPetDetails.notes}</Value>
              </Notes>
            )}

            <MapContainer>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  center={{
                    lat: petData.lostPetDetails.latitude,
                    lng: petData.lostPetDetails.longitude,
                  }}
                  zoom={15}
                >
                  <Marker
                    position={{
                      lat: parseFloat(petData.lostPetDetails.latitude),
                      lng: parseFloat(petData.lostPetDetails.longitude),
                    }}
                  />
                </GoogleMap>
              </LoadScript>
            </MapContainer>
          </LostPetInfo>
        )}
        <RowContainer>
          <Tabs>
            <Tab
              onClick={() => handleTabClick("About")}
              active={activeTab === "About"}
            >
              <FaInfoCircle /> About
            </Tab>
            <Tab
              onClick={() => handleTabClick("Skills")}
              active={activeTab === "Skills"}
            >
              <GiSkills /> Skills
            </Tab>
          </Tabs>
          {!petData.isRecordsPrivate && (
            <WellnessContainer>
              {wellnessCategories.map((category) => (
                <WellnessCategory
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  active={activeTab === category.title}
                >
                  {getCategoryIcon(category.id)} {category.title}
                </WellnessCategory>
              ))}
            </WellnessContainer>
          )}
        </RowContainer>
        <TabContent>
          {activeTab && (
            <div>
              <SubcategoryTabs>
                {subcategories.map((subcategory) => (
                  <SubcategoryTab
                    key={subcategory.id}
                    onClick={() => setActiveSubcategoryTab(subcategory.title)}
                    active={activeSubcategoryTab === subcategory.title}
                  >
                    {subcategory.title}
                  </SubcategoryTab>
                ))}
              </SubcategoryTabs>
              {subcategories.some(
                (subcategory) =>
                  activeSubcategoryTab === subcategory.title &&
                  subcategory.records &&
                  subcategory.records.length > 0
              ) && (
                <RecordsContainer>
                  {subcategories.map(
                    (subcategory) =>
                      activeSubcategoryTab === subcategory.title && (
                        <RecordsList key={subcategory.id}>
                          {subcategory.records.map((record) => (
                            <RecordCard key={record.id}>
                              <RecordHeader>
                                <RecordDate>
                                  {new Date(
                                    record.actionDate
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                                </RecordDate>
                                <RecordValue>
                                  {record.recordValue} {record.unitOfMeasure}
                                </RecordValue>
                              </RecordHeader>
                              {record.notes && (
                                <RecordNotes>{record.notes}</RecordNotes>
                              )}
                              {record.image && (
                                <RecordImageWrapper>
                                  <RecordImage
                                    src={record.imagePath}
                                    alt="Record"
                                  />
                                </RecordImageWrapper>
                              )}
                            </RecordCard>
                          ))}
                        </RecordsList>
                      )
                  )}
                </RecordsContainer>
              )}
            </div>
          )}
          {activeTab === "About" && (
            <AboutSection>
              <InfoItem>
                <InfoIcon>
                  <FaBirthdayCake />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Birthdate</InfoTitle>
                  <InfoValue>
                    {new Date(petData.dob).toLocaleDateString()}
                  </InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem>
                <InfoIcon>
                  <FaWeight />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Weight</InfoTitle>
                  <InfoValue>{petData.weight}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem>
                <InfoIcon>
                  <FaTransgender />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Gender</InfoTitle>
                  <InfoValue>{petData.petGender}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem>
                <InfoIcon>
                  <FaPalette />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Color</InfoTitle>
                  <InfoValue>
                    <ColorCircle color={petData.color} />
                  </InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem>
                <InfoIcon>
                  <FaMicrochip />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Microchip ID</InfoTitle>
                  <InfoValue>{petData.microChipId}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem>
                <InfoIcon>
                  <FaIdCard />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Public ID</InfoTitle>
                  <InfoValue>{petData.publicId || "N/A"}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem>
                <InfoIcon>
                  <FaSyringe />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Neutered</InfoTitle>
                  <InfoValue>{petData.isSterilized ? "Yes" : "No"}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem>
                <InfoIcon>
                  <FaSyringe />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Blood Type</InfoTitle>
                  <InfoValue>{petData.bloodType}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <SectionTitle>Pet Owner Info</SectionTitle>

              <InfoItem>
                <InfoIcon>
                  <FaUser />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Owner Username</InfoTitle>
                  <InfoValue>{petData.ownerName}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem>
                <InfoIcon>
                  <FaPhone />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Phone Number</InfoTitle>
                  <InfoValue>{petData.ownderContactPhone || "N/A"}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <InfoItem style={{ gridColumn: "1 / -1" }}>
                <InfoIcon>
                  <FaEnvelope />
                </InfoIcon>
                <InfoDetails>
                  <InfoTitle>Contact Email</InfoTitle>
                  <InfoValue>{petData.ownerContactEmail}</InfoValue>
                </InfoDetails>
              </InfoItem>

              <SectionTitle>About {petData.petName}</SectionTitle>
              <Description>
                <p>{petData.description}</p>
              </Description>
            </AboutSection>
          )}
          {activeTab === "Skills" && (
            <SkillsSection>
              {petData.petSkills.map((skill) => (
                <Skill key={skill.id}>
                  <SkillImage src={skill.image} alt={skill.title} />
                  <SkillTitle>{skill.title}</SkillTitle>
                </Skill>
              ))}
            </SkillsSection>
          )}
        </TabContent>
      </Content>
    </ProfileContainer>
  );
};

const OrangeButton = styled.button`
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  background: orange;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: darkorange;
  }

  @media (max-width: 768px) {
    padding: 6px 8px;
    margin: 3px;
    font-size: 14px;
    border-radius: 4px;
  }
`;

const ProfileContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 50px;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
`;

const BreedText = styled.p`
  color: #5951fc;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
`;

const PetDetails = styled.div`
  background: white;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 32px;
  align-items: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    flex-direction: row;
    text-align: left;
    padding: 12px;
    gap: 12px;
  }
`;

const PetImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 16px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
    min-width: 100px;
  }
`;

const PetInfo = styled.div`
  h1 {
    font-size: 2.5rem;
    margin: 0 0 8px 0;
    color: #2d3436;

    @media (max-width: 768px) {
      font-size: 1.4rem;
      margin: 0 0 2px 0;
    }
  }

  p {
    margin: 4px 0;
    color: #636e72;
    font-size: 1.1rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
      margin: 1px 0;
    }
  }
`;

const LostPetButton = styled.button`
  background-color: #ff4757;
  color: white;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #ff6b81;
    transform: scale(1.02);
  }

  svg {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
`;

const LostPetInfo = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 10px;
    margin: 10px 0;
    width: auto; // Allow container to adjust to screen width
    max-width: 100%; // Ensure it doesn't overflow
    box-sizing: border-box; // Include padding in width calculation
  }
`;

const LostPetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    color: #ff4757;
    font-size: 1.5rem;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0 5px;

  &:hover {
    color: #ff4757;
  }
`;

const LostPetGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Stack items vertically on mobile
    gap: 10px;
  }
`;

const LostPetDetail = styled.div`
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
`;

const Label = styled.div`
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 4px;
`;

const Value = styled.div`
  font-size: 1rem;
  color: #2d3436;
  font-weight: 500;
`;

const Notes = styled.div`
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 15px;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 15px;
`;

const ColorCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color || "transparent"};
  display: inline-block;
  margin-left: 5px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin: 20px 0;

  @media (max-width: 768px) {
    gap: 8px;
    margin: 12px 0;
  }
`;

const Tab = styled.button`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: ${(props) => (props.active ? "rgba(89, 81, 252, 0.1)" : "none")};
  border: none;
  cursor: pointer;
  color: ${(props) => (props.active ? "#5951fc" : "#666")};
  font-size: 16px;
  border-radius: 4px;
  transition: all 0.2s ease;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: #5951fc;
    background: rgba(89, 81, 252, 0.05);
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 12px;
    min-width: 0;
    flex: 1;
    text-align: center;

    svg {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }
  }
`;

const WellnessContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
  }
`;

const WellnessCategory = styled.button`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: ${(props) => (props.active ? "rgba(89, 81, 252, 0.1)" : "none")};
  border: none;
  cursor: pointer;
  color: ${(props) => (props.active ? "#5951fc" : "#666")};
  font-size: 16px;
  border-radius: 4px;
  transition: all 0.2s ease;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: #5951fc;
    background: rgba(89, 81, 252, 0.05);
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 12px;
    min-width: 0;
    flex: 1;
    text-align: center;

    svg {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }
  }
`;

const TabContent = styled.div`
  background: white;
  border-radius: 16px;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const AboutSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 12px;
  transition: transform 0.2s ease;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
    background: #f1f2f6;
  }

  @media (max-width: 768px) {
    padding: 8px;
    gap: 6px;
  }
`;

const SectionTitle = styled.div`
  grid-column: 1 / -1;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 1.2em;
  font-weight: 600;
  color: #2d3436;
`;

const Description = styled.div`
  grid-column: 1 / -1;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 16px;

  p {
    margin: 0;
    line-height: 1.6;
  }
`;

const InfoIcon = styled.div`
  color: #5951fc;
  font-size: 1.2rem;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f2f6;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 1rem;
    min-width: 24px;
    height: 24px;
  }
`;

const InfoDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 0;
  flex: 1;
`;

const InfoTitle = styled.p`
  font-size: 0.8rem;
  color: #666;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const InfoValue = styled.p`
  font-size: 1rem;
  margin: 0;
  color: #2d3436;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const SkillsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
  padding: 16px;
`;

const Skill = styled.div`
  background: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
  text-align: center;

  &:hover {
    transform: translateY(-4px);
  }
`;

const SkillImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const SkillTitle = styled.p`
  margin-top: 5px;
  text-align: center;
`;

const SubcategoryTabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

const SubcategoryTab = styled.button`
  background: ${(props) => (props.active ? "#FF5722" : "#ffe0b2")};
  color: ${(props) => (props.active ? "white" : "black")};
  border: 1px solid ${(props) => (props.active ? "#FF5722" : "#ffe0b2")};
  padding: 10px 15px;
  margin: 5px;
  border-radius: 15px;
  cursor: pointer;
  transition: background 0.3s;
  font-weight: ${(props) => (props.active ? "600" : "normal")};

  &:hover {
    background: ${(props) => (props.active ? "#e64a19" : "#ffcc80")};
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
    margin: 3px;
    font-size: 12px;
    border-radius: 12px;
  }
`;

const RecordsContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  background: #f8f9fa;
`;

const RecordsList = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const RecordCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const RecordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const RecordDate = styled.span`
  color: #666;
  font-size: 0.9rem;
`;

const RecordValue = styled.span`
  color: #5951fc;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 4px 12px;
  background: rgba(89, 81, 252, 0.1);
  border-radius: 20px;
`;

const RecordNotes = styled.p`
  color: #2d3436;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 8px 0;
  padding: 8px;
  background: #f8f9fa;
  border-radius: 8px;
`;

const RecordImageWrapper = styled.div`
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
`;

const RecordImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
`;

const AdoptButton = styled.button`
  background-color: orange;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 30px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  animation: flickerAnimation 1.5s infinite; /* Add this line for flicker effect */

  &:hover {
    background-color: orange;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px 16px;
  }

  /* Flicker animation keyframes */
  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
`;
export default PetProfile;
