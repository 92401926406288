import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { useLocation } from 'react-router-dom';
import { verifyEmail } from '../../connectors/Authentication'; // Adjust the import path as needed

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

useEffect(() => {
  const EmailVerify = async () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (token) {
      try {
        await verifyEmail(token);
        setSuccessMessage('Email Verified.');
      } catch (error) {
        setErrorMessage('An error occurred while verifying your email.');
        console.error('Email verification error:', error);
      }
    }
  };

  EmailVerify();
}, [location.search]);


  return (
    <Container>
      <Header>
        <LogoContainer>
          <Logo onClick={() => { navigate('/'); }} src={logo} alt="Pawers Logo" />
        </LogoContainer>
      </Header>
      <Content>
        {successMessage && <Message success>{successMessage}</Message>}
        {errorMessage && <Message>{errorMessage}</Message>}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f5f7fc; 
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 600px) {
    width: 150px;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const Message = styled.div`
  color: ${props => (props.success ? 'green' : 'red')};
  font-size: 18px;
  margin-top: 20px;
`;

export default VerifyEmail;
