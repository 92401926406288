import React from 'react';
import styled from 'styled-components';
import { FaUser, FaLock } from 'react-icons/fa';

const TextField = ({ type = 'text', placeholder, value, onChange }) => {
    let icon;

    if (type === 'username') {
        icon = <FaUser />;
    } else if (type === 'password') {
        icon = <FaLock />;
    }

    return (
        <InputContainer>
            <StyledInput
                type={type}
                placeholder={placeholder || 'Enter your text here'}
                value={value}
                onChange={onChange}
                className="text-field"
            />
            {icon && <IconWrapper>{icon}</IconWrapper>}
        </InputContainer>
    );
};

const StyledInput = styled.input`
    border-radius: 8px;
    padding: 15px 15px 15px 50px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    font-size: 18px;
`;

const InputContainer = styled.div`
    position: relative;
`;

const IconWrapper = styled.div`
    position: absolute;
    left: 10px;
    top: 45%;
    transform: translateY(-50%);
    color: #aaa;
    pointer-events: none;
`;

export default TextField;
