import { postRequest, getRequest } from '../utils/request';

// ResetPassword endpoint
export const resetPassword = async (newPassword, passwordResetToken) => {
  try {
    const body = {
        newPassword: newPassword,
        passwordResetToken: passwordResetToken,
      };
    const response = await postRequest('/api/auth/resetPassword', body);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to reset password:', error);
    throw error;
  }
};

// verifyEmail endpoint
export const verifyEmail = async (verificationToken) => {
  try {
    const body = {
        verificationToken: verificationToken,
      };
    const response = await postRequest('/api/Accounts/VerifyEmail', body);

    if (response.result === 1) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Failed to reset password:', error);
    throw error;
  }
};

// getCountries endpoint
export const getCountries = async () => {
    const response = await getRequest(`/api/General/GetCountries`);
    if (response.result === 0) {
        throw new Error(response.message); // Handle error
    }
    return response.data;
};